import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";

import { makeStyles, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";

import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import MyImage from "./MyImage";
import quadratyellow from "../common/quadratyellow.jpg";
import theroundblack from "../common/theroundblack.png";
import trianglepurple from "../common/trianglepurple.png";
import quadratblue from "../common/quadratblue.jpg";
import theroundgreen from "../common/theroundgreen.png";
import trianglered from "../common/trianglered.png";
import UseArrowKeys from "../common/UseArrowKeys";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "20px"
  },
  middleText: {
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    fontSize: "1.4em"
  },
  leftText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    height: "40px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    marginLeft: "auto",
    marginRight: "20px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  buttonBlock: {
    marginBottom: "15px",
    marginTop: "30px"
  },
  rightText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    height: "40px",
    marginRight: "auto",
    marginLeft: "20px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  block: {
    display: "flex",
    marginBottom: "15px",
    marginTop: "15px",
    width: "100%",
    padding: "5px"
  },
  mainBlock: {
    display: "inline-block",
    width: "50%",
    margin: "10px 40px 10px 40px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  leftButton: {
    margin: "5px 20px 5px 0px",
    background: "red",
    "&:hover": {
      background: "black"
    }
  },
  rightButton: {
    margin: "5px 0px 5px 20px",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  beginButton: {
    width: "20%",
    margin: "20px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  }
});

const FourthGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(4);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [firstImage, setFirstImage] = React.useState(trianglepurple);
  const [secondImage, setSecondImage] = React.useState(theroundblack);
  const [thirdImage, setThirdImage] = React.useState(trianglered);
  const [fourthImage, setFourthImage] = React.useState(quadratblue);
  const [isAnimating, setAnimating] = React.useState(false);
  const [firstImageOpacity, setFirstImageOpacity] = React.useState("1");
  const [secondImageOpacity, setSecondImageOpacity] = React.useState("1");
  const matches = useMediaQuery("(max-width:400px)");

  const classes = useStyles();
  const dispatch = useDispatch();
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];
  const sameBank = ["SAME", "ОДИНАКОВЫЕ", "VIENODI", "GLEICH"];
  const differentBank = ["DIFFERENT", "РАЗНЫЕ", "SKIRTINGI", "VERSCHIEDEN"];
  const instructionsBank = [
    "Are figures in black squares the same?",
    "Одинаковы ли фигуры в черных квадратах?",
    "Ar figuros juodose kvadratuose yra vienodi?",
    "Sind die Figuren in schwarzen Quadraten gleich?"
  ];

  const checkKey = e => {
    if (e.keyCode === 37) {
      falsePressed();
    } else if (e.keyCode === 39) {
      truePressed();
    }
  };

  React.useEffect(() => {
    document.onkeydown = checkKey;
    return () => {
      document.onkeydown = undefined;
    };
  });

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const imageBank = [
    quadratblue,
    quadratyellow,
    theroundblack,
    theroundgreen,
    trianglepurple,
    trianglered
  ];

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "gamedad2dadkla9udajdsd2a"
    );
    dispatch(postRecord(4, encryptedAES.toString()));
  };

  const postNewPoints = points => {
    if (points > 0) {
      dispatch(postPoints(points));
    }
  };

  const beginGame = () => {
    setLevel(1);
    setMistakes(0);
    setPoints(0);
    setCounter(4);
    setGameRunning(true);
    generateAnswer();
  };

  const generateAnswer = () => {
    if (level > 30) {
      setCounter(13);
    } else if (level > 20) {
      setCounter(10);
    } else if (level > 10) {
      setCounter(7);
    }
    if (Math.random() * 100 > 50) {
      setAnswer(true);
      showView(true);
    } else {
      setAnswer(false);
      showView(false);
    }
  };

  const showView = myAnswer => {
    if (myAnswer) {
      setFourthImage(secondImage);
      setAnimating(true);
    } else {
      var valued = true;
      while (valued) {
        const tempImage =
          imageBank[Math.round(Math.random() * (imageBank.length - 1))];
        if (tempImage !== secondImage) {
          valued = false;
          setFourthImage(tempImage);
          setAnimating(true);
        }
      }
    }
  };

  const animationEnded = () => {
    setFirstImage(secondImage);
    setSecondImage(thirdImage);
    setThirdImage(fourthImage);
    setAnimating(false);
    beginCountdown();
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const beginCountdown = () => {
    setIsAnswering(true);
  };

  const truePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          changeOpacity();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const changeOpacity = () => {
    var numberFirst = firstImageOpacity * 1;
    if (numberFirst > 0.5) {
      numberFirst -= 0.2;
      setFirstImageOpacity(`${numberFirst}`);
      generateAnswer();
    } else {
      if (numberFirst !== 0) {
        numberFirst -= 0.2;
        setFirstImageOpacity(`${numberFirst}`);
      }
      var numberSecond = secondImageOpacity * 1;
      if (numberSecond !== 0) {
        numberSecond -= 0.2;
        setSecondImageOpacity(`${numberSecond}`);
      }
      generateAnswer();
    }
  };

  const falsePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (!answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          changeOpacity();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const mistakeMade = () => {
    setFirstImageOpacity("1");
    setSecondImageOpacity("1");
    if (level > 1) {
      setLevel(1);
      setCounter(4);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        postNewPoints(points - 50);
      }
    } else {
      setTimeout(generateAnswer, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={4}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionsBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}
        <div
          style={{
            width: matches ? "239px" : "530px",
            display: "inline-block",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "5px",
            borderColor: "#000000",
            height: matches ? "130px" : "300px"
          }}
        >
          <div
            style={{
              width: matches ? "75px" : "150px",
              display: "inline-block",
              borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "5px",
              borderColor: "#000000",
              height: matches ? "80px" : "150px",
              marginTop: matches ? "25px" : "75px",
              float: "left",
              marginLeft: matches ? "3px" : "20px"
            }}
          >
            {isAnimating && (
              <MyImage
                myOpacity={firstImageOpacity}
                image={firstImage}
                onComplete={animationEnded}
              />
            )}
            {!isAnimating && (
              <img
                style={{
                  width: matches ? "45px" : "120px",
                  height: matches ? "45px" : "120px",
                  marginTop: "15px",
                  marginBottom: "auto",
                  marginLeft: matches ? "3px" : "15px",
                  marginRight: matches ? "3px" : "15px",
                  opacity: firstImageOpacity
                }}
                src={firstImage}
                alt="star"
              />
            )}
          </div>
          <div
            style={{
              width: matches ? "75px" : "150px",
              display: "inline-block",
              height: matches ? "75px" : "150px",
              marginTop: matches ? "25px" : "75px",
              float: "left",
              marginLeft: matches ? "3px" : "20px"
            }}
          >
            {isAnimating && (
              <MyImage
                myOpacity={secondImageOpacity}
                image={secondImage}
                onComplete={animationEnded}
              />
            )}
            {!isAnimating && (
              <img
                style={{
                  width: matches ? "45px" : "120px",
                  height: matches ? "45px" : "120px",
                  marginTop: "15px",
                  marginBottom: "auto",
                  marginLeft: matches ? "3px" : "15px",
                  marginRight: matches ? "3px" : "15px",
                  opacity: secondImageOpacity
                }}
                src={secondImage}
                alt="triangle"
              />
            )}
          </div>
          <div
            style={{
              width: matches ? "75px" : "150px",
              display: "inline-block",
              borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "5px",
              borderColor: "#000000",
              height: matches ? "75px" : "150px",
              marginTop: matches ? "25px" : "75px",
              float: "left",
              marginLeft: matches ? "3px" : "20px"
            }}
          >
            {isAnimating && (
              <MyImage image={thirdImage} onComplete={animationEnded} />
            )}
            {!isAnimating && (
              <img
                style={{
                  width: matches ? "45px" : "120px",
                  height: matches ? "45px" : "120px",
                  marginTop: "15px",
                  marginBottom: "auto",
                  marginLeft: matches ? "3px" : "15px",
                  marginRight: matches ? "3px" : "15px"
                }}
                src={thirdImage}
                alt="Logo"
              />
            )}
          </div>
        </div>

        <div className={classes.buttonBlock}>
          <Button
            className={classes.leftButton}
            size="medium"
            variant="contained"
            color="primary"
            style={{
              width: matches ? "40%" : "25%",
              fontSize: matches ? "1em" : "1.4em"
            }}
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              falsePressed();
            }}
          >
            {differentBank[id]}
          </Button>
          <Button
            className={classes.rightButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            style={{
              width: matches ? "40%" : "25%",
              fontSize: matches ? "1em" : "1.4em"
            }}
            onClick={e => {
              e.preventDefault();
              truePressed();
            }}
          >
            {sameBank[id]}
          </Button>
        </div>
        {!matches && <UseArrowKeys />}
        <Button
          className={classes.beginButton}
          size="medium"
          variant="contained"
          color="primary"
          style={{ width: matches ? "50%" : "20%" }}
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default FourthGame;
