import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import PropTypes from "prop-types";

const useStyles = makeStyles({
  coloredButton: {
    fontWeight: "bold",
    color: "white",
    padding: "0px",
    background: "grey",
    "&:hover": {
      background: "black"
    }
  }
});

const MyColoredButton = ({
  isGameRunning,
  id,
  isHighlighted,
  checkAnswer,
  values,
  valuesId
}) => {
  const matches = useMediaQuery("(max-width:400px)");
  const matchesTwo = useMediaQuery("(max-width:320px)");

  const classes = useStyles();
  return (
    <Button
      className={classes.coloredButton}
      variant="contained"
      style={{
        background: isHighlighted.includes(id) ? "green" : "",
        width: matchesTwo ? "18px" : matches ? "25px" : "60px",
        height: matchesTwo ? "18px" : matches ? "25px" : "60px",
        minWidth: matchesTwo ? "18px" : matches ? "25px" : "60px",
        margin: matches ? "2px" : "4px"
      }}
      color="primary"
      disabled={!isGameRunning}
      onClick={e => {
        e.preventDefault();
        checkAnswer(id);
      }}
    >
      {valuesId.includes(id) ? values[valuesId.indexOf(id)] : ""}
    </Button>
  );
};
MyColoredButton.propTypes = {
  isGameRunning: PropTypes.bool,
  id: PropTypes.number,
  isHighlighted: PropTypes.array,
  valuesId: PropTypes.array,
  values: PropTypes.array,
  checkAnswer: PropTypes.func
};
MyColoredButton.defaultProps = {
  valuesId: [],
  isHighlighted: []
};
export default MyColoredButton;
