import React from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LanguageIcon from "@material-ui/icons/Language";
import Button from "@material-ui/core/Button";

import { changeLanguage } from "../../state/actions/language";

import ger from "./ger.png";
import uk from "./uk.jpg";
import rus from "./rus.png";
import ltu from "./ltu.png";
import chi from "./chi.png";

const useStyles = makeStyles({
  icon: {
    width: "33px",
    height: "33px",
    marginTop: "0.35px",
    float: "right",
    border: "solid",
    borderColor: "black",
    borderWidth: "0.5px",
    borderRadius: "50px"
  },
  languageblock: {
    right: "0",
    position: "absolute",
    display: "block",
    width: "180px"
  },
  languagemenu: {
    height: "80px",
    display: "inline-block",
    paddingTop: "15px",
    width: "100%"
  },
  languagetext: {
    color: "white",
    float: "right",
    width: "100px",
    textAlign: "center",
    verticalAlign: "middle",
    padding: "0px",
    position: "absolute",
    top: "50%",
    margin: "0 0 2px 0",
    transform: "translate(0, -50%)",
    "&:hover": {
      color: "rgba(171, 190, 231)",
      cursor: "pointer"
    }
  },
  buttonchik: {
    height: "35px",
    width: "137px",
    float: "right",
    border: "solid",
    background: "black",
    borderWidth: "1px",
    borderColor: "grey",
    borderRadius: "50px",
    position: "relative",
    "&:hover": {
      color: "rgba(171, 190, 231)",
      cursor: "pointer"
    }
  },
  iconmenubutton: {
    height: "55px",
    color: "#ffffff",
    fontSize: "2em",
    float: "right",
    textTransform: "none",
    borderRadius: "5px",
    borderColor: "#FFFFFF",
    borderWidth: "1px",
    borderStyle: "solid",
    "&:hover": {
      color: "#1D73AD",
      background: "black"
    },
    "&:focus": {
      color: "#1D73AD"
    }
  },
  language: {
    width: "180px"
  }
});

const LagnuageBlock = () => {
  const [show, setShow] = React.useState("none");
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:710px)");

  const showLanguages = () => {
    if (show === "none") {
      setShow("inline-block");
    } else {
      setShow("none");
    }
  };

  const englishSelected = () => {
    setShow("none");
    dispatch(changeLanguage(0));
  };

  const lithuanianSelected = () => {
    setShow("none");
    dispatch(changeLanguage(2));
  };

  const germanSelected = () => {
    setShow("none");
    dispatch(changeLanguage(3));
  };

  const russianSelected = () => {
    setShow("none");
    dispatch(changeLanguage(1));
  };

  const chineseSelected = () => {
    setShow("none");
    dispatch(changeLanguage(4));
  };

  return (
    <div
      className={classes.languageblock}
      style={{ marginRight: matches ? "20px" : "120px", zIndex: "3" }}
    >
      <div className={classes.languagemenu}>
        <Button className={classes.iconmenubutton} onClick={showLanguages}>
          <LanguageIcon />
        </Button>
      </div>
      <div className={classes.language} style={{ display: show }} elevation={5}>
        <div
          className={classes.buttonchik}
          style={{ marginTop: "8px" }}
          onClick={englishSelected}
        >
          <img className={classes.icon} src={uk} alt="uk" />
          <p className={classes.languagetext} style={{ marginTop: "0px" }}>
            English
          </p>
        </div>
      </div>
      <div className={classes.language} style={{ display: show }} elevation={5}>
        <div
          className={classes.buttonchik}
          style={{ marginTop: "8px" }}
          onClick={russianSelected}
        >
          <img className={classes.icon} src={rus} alt="rus" />
          <p className={classes.languagetext}>Русский</p>
        </div>
      </div>
      <div className={classes.language} style={{ display: show }} elevation={5}>
        <div
          className={classes.buttonchik}
          style={{ marginTop: "8px" }}
          onClick={lithuanianSelected}
        >
          <img
            className={classes.icon}
            style={{ height: "32.5px" }}
            src={ltu}
            alt="ltu"
          />
          <p className={classes.languagetext}>Lietuvių</p>
        </div>
      </div>
      <div className={classes.language} style={{ display: show }} elevation={5}>
        <div
          className={classes.buttonchik}
          onClick={germanSelected}
          style={{ marginTop: "8px" }}
        >
          <img
            className={classes.icon}
            style={{ height: "32.35px" }}
            src={ger}
            alt="ger"
          />
          <p className={classes.languagetext}>Deutsch</p>
        </div>
      </div>
      <div className={classes.language} style={{ display: show }} elevation={5}>
        <div
          className={classes.buttonchik}
          onClick={chineseSelected}
          style={{ marginTop: "8px" }}
        >
          <img
            className={classes.icon}
            style={{ height: "32.35px" }}
            src={chi}
            alt="chi"
          />
          <p className={classes.languagetext}>Chinese</p>
        </div>
      </div>
    </div>
  );
};

export default LagnuageBlock;
