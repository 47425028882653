import React from "react";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import de1 from "../common/screenshots/de1.png";
import de2 from "../common/screenshots/de2.png";
import de3 from "../common/screenshots/de3.png";
import de4 from "../common/screenshots/de4.png";
import de5 from "../common/screenshots/de5.png";

import en1 from "../common/screenshots/en1.png";
import en2 from "../common/screenshots/en2.png";
import en3 from "../common/screenshots/en3.png";
import en4 from "../common/screenshots/en4.png";
import en5 from "../common/screenshots/en5.png";

import ru1 from "../common/screenshots/ru1.png";
import ru2 from "../common/screenshots/ru2.png";
import ru3 from "../common/screenshots/ru3.png";
import ru4 from "../common/screenshots/ru4.png";
import ru5 from "../common/screenshots/ru5.png";

import lt1 from "../common/screenshots/lt1.png";
import lt2 from "../common/screenshots/lt2.png";
import lt3 from "../common/screenshots/lt3.png";
import lt4 from "../common/screenshots/lt4.png";
import lt5 from "../common/screenshots/lt5.png";

const useStyles = makeStyles({
  slideshow: {
    width: "100%",
    display: "flex",
    marginBottom: "10px",
    marginTop: "10px",
  },
  buttonchikLeft: {
    marginLeft: "auto",
    background: "transparent",
    borderWidth: "0px",
    color: "white",
    paddingRight: "8px",
    fontSize: "2em",
    "&:hover": {
      cursor: "pointer",
    },
    "&:focus": { outline: "none" },
  },
  buttonchikRight: {
    marginRight: "auto",
    background: "transparent",
    borderWidth: "0px",
    color: "white",
    paddingLeft: "8px",
    fontSize: "2em",
    "&:hover": {
      cursor: "pointer",
    },
    "&:focus": { outline: "none" },
  },
});

const ScreenshotCarousel = () => {
  const id = useSelector((store) => store.language.id);
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:670px)");
  const imgBank = [
    [en1, en2, en3, en4, en5],
    [ru1, ru2, ru3, ru4, ru5],
    [lt1, lt2, lt3, lt4, lt5],
    [de1, de2, de3, de4, de5],
    [en1, en2, en3, en4, en5],
  ];
  const imgArray = imgBank[id];
  const [imgIndex, setImgIndex] = React.useState(0);

  React.useEffect(() => {
    function change() {
      if (imgIndex === imgArray.length - 1) {
        setImgIndex(0);
      } else {
        setImgIndex(imgIndex + 1);
      }
    }
    const timer = setInterval(change, 4000);
    return () => {
      clearInterval(timer);
    };
  });

  const showLeft = () => {
    if (imgIndex === 0) {
      setImgIndex(imgArray.length - 1);
    } else {
      setImgIndex(imgIndex - 1);
    }
  };

  const showRight = () => {
    if (imgIndex === imgArray.length - 1) {
      setImgIndex(0);
    } else {
      setImgIndex(imgIndex + 1);
    }
  };

  return (
    <div className={classes.slideshow}>
      <button className={classes.buttonchikLeft} onClick={showLeft}>
        &#10094;
      </button>
      <img
        alt="img"
        style={{
          maxWidth: "80%",
          maxHeight: matches ? "150px" : "1500px",
          borderRadius: "20px",
        }}
        src={imgArray[imgIndex]}
      />
      <button className={classes.buttonchikRight} onClick={showRight}>
        &#10095;
      </button>
    </div>
  );
};
export default ScreenshotCarousel;
