import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Formik } from "formik";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { createUser, hideSignUpError } from "../../state/actions/user";
import {
  passwordValidation,
  emailValidation,
  nicknameValidation,
  nicknameLengthValidation,
  emailTypeValidation,
  whitespaceValidation,
  passwordLengthValidation,
  passwordsMatchValidation,
  passwordContainValidation,
  maxNicknameLength
} from "../../common/TextFieldValidation";
import FormError from "../common/FormError";

const whitespacesBank = [
  "Whitespaces are not allowed",
  "Пробелы не допускаются",
  "Tarpai neleidžiami",
  "Leerzeichen sind nicht erlaubt"
];
const mustNotBank = [
  "Must not exceed",
  "Не должнo превышать",
  "Neturi viršyti",
  "Darf nicht überschreiten"
];
const symbolsBank = ["symbols", "символов", "ženklų", "Zeichen"];
const emailErrorBank = [
  "Email must be valid",
  "Email должен быть действительным",
  "El. Paštas turi būti galiojantis",
  "Email muss gültig sein"
];
const passwordMatchBank = [
  "Passwords must match",
  "Пароли должны совпадать",
  "Slaptažodžiai turi sutapti",
  "Passwörter müssen übereinstimmen"
];
const passwordContainBank = [
  "Password must contain at least 1 letter and 1 number",
  "Пароль должен содержать не менее 1 буквы и 1 цифры",
  "Slaptažodyje turi būti bent 1 raidė ir 1 numeris",
  "Passwort muss mindestens 1 Buchstabe und 1 Ziffer enthalten"
];
const passwordLengthBank = [
  "Must be at least 8 characters long",
  "Должно быть не менее 8 символов",
  "Turi būti ne mažiau kaip 8 simboliai",
  "Muss mindestens 8 Zeichen lang sein"
];
const notInUse = [
  "Must be not already in use",
  "Не должен быть в использовании",
  "Turi būti dar nenaudojamas",
  "Muss noch nicht in Gebrauch sein"
];
const passwordBank = ["Password *", "Пароль *", "Slaptažodis *", "Passwort *"];
const repeatPasswordBank = [
  "Repeat password *",
  "Повторите пароль *",
  "Pakartokite slaptažodį *",
  "Passwort wiederholen *"
];
const registerBank = [
  "Sign up",
  "Зарегистрироваться",
  "Užsiregistruoti",
  "Anmelden"
];
const correctBank = [
  "Please correct any invalid fields and try again",
  "Пожалуйста исправьте все недействительные поля и попробуйте снова",
  "Ištaisykite netinkamus laukus ir bandykite dar kartą",
  "Bitte korrigieren Sie alle ungültigen Felder und versuchen Sie es erneut"
];
const failedBank = [
  "Sign up failed",
  "Регистрация прошла неудачно",
  "Registracija nepavyko",
  "Anmeldung gescheitert"
];
const title = ["Registration", "Регистрация", "Registravimas", "Anmeldung"];
const minimumBank = [
  "Minimum 8 characters",
  "Минимум 8 символов",
  "Mažiausiai 8 simboliai",
  "Mindestens 8 Zeichen"
];
const maxBank = ["Maximum", "Максимально", "Daugiausiai", "Maximal"];

const SignUpForm = () => {
  const matches = useMediaQuery("(max-width:500px)");
  const [localError, setLocalError] = React.useState(false);
  const error = useSelector(store => store.user.signUpError);
  const id = useSelector(store => store.language.id);
  const user = useSelector(store => store.user.user);
  const isSubmiting = useSelector(store => store.user.isSubmiting);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (user) {
      history.push("/games/all");
    }
  }, [history, user]);

  const verifyForm = (nickname, email, passwordOne, passwordTwo) => {
    if (
      nicknameValidation(nickname) &&
      emailValidation(email) &&
      passwordValidation(passwordOne) &&
      passwordsMatchValidation(passwordOne, passwordTwo)
    ) {
      setLocalError(false);
      submit(nickname, email, passwordOne);
    } else {
      if (error) {
        dispatch(hideSignUpError());
      }
      setLocalError(true);
    }
  };

  const redirect = () => {
    history.push("/games-all");
  };

  const submit = (nickname, email, password) => {
    const formData = {
      nickname,
      email,
      password
    };
    dispatch(createUser(formData, redirect, id));
    setTimeout(() => {
      dispatch(hideSignUpError());
    }, 10000);
  };

  const nicknameError = nickname => {
    if (!whitespaceValidation(nickname)) {
      return <FormError message={whitespacesBank[id]} />;
    }
    if (!nicknameLengthValidation(nickname)) {
      return (
        <FormError
          message={`${mustNotBank[id]} ${maxNicknameLength} ${symbolsBank[id]}`}
        />
      );
    }
  };

  const emailError = email => {
    if (!whitespaceValidation(email)) {
      return <FormError message={whitespacesBank[id]} />;
    }
    if (!emailTypeValidation(email)) {
      return <FormError message={emailErrorBank[id]} />;
    }
  };

  const firstPasswordError = password => {
    if (!passwordContainValidation(password)) {
      return <FormError message={passwordContainBank[id]} />;
    }
    if (!whitespaceValidation(password)) {
      return <FormError message={whitespacesBank[id]} />;
    }
    if (!passwordLengthValidation(password)) {
      return <FormError message={passwordLengthBank[id]} />;
    }
  };

  const secondPasswordError = (passwordOne, passwordTwo) => {
    if (!passwordsMatchValidation(passwordOne, passwordTwo)) {
      return <FormError message={passwordMatchBank[id]} />;
    }
  };

  return (
    <Container maxWidth="sm">
      <Card
        style={{
          borderRadius: "20px",
          padding: "20px",
          marginBottom: "20px",
          marginTop: "20px",
          background: "rgba(255, 255, 255, 0.9)"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: matches ? "1.6em" : "2em",
            marginTop: matches ? "5px" : "10px",
            marginBottom: matches ? "5px" : "20px"
          }}
        >
          {title[id]}
        </h1>
        <Formik
          initialValues={{
            nickname: "",
            email: "",
            passwordOne: "",
            passwordTwo: ""
          }}
          onSubmit={values => {
            verifyForm(
              values.nickname,
              values.email,
              values.passwordOne,
              values.passwordTwo
            );
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                required
                fullWidth
                name="nickname"
                variant="outlined"
                placeholder="Nickname *"
                helperText={`${maxBank[id]} ${maxNicknameLength} ${symbolsBank[id]}`}
                style={{ marginTop: "20px" }}
                value={values.nickname}
                onChange={handleChange}
              />
              {localError && nicknameError(values.nickname)}
              <TextField
                required
                name="email"
                fullWidth
                variant="outlined"
                placeholder="Email *"
                helperText={notInUse[id]}
                style={{ marginTop: "20px" }}
                value={values.email}
                onChange={handleChange}
              />
              {localError && emailError(values.email)}
              <TextField
                required
                fullWidth
                name="passwordOne"
                variant="outlined"
                placeholder={passwordBank[id]}
                type="password"
                helperText={minimumBank[id]}
                style={{ marginTop: "20px" }}
                value={values.passwordOne}
                onChange={handleChange}
              />
              {localError && firstPasswordError(values.passwordOne)}
              <TextField
                required
                fullWidth
                name="passwordTwo"
                variant="outlined"
                placeholder={repeatPasswordBank[id]}
                type="password"
                style={{ marginTop: "20px" }}
                value={values.passwordTwo}
                onChange={handleChange}
              />
              {localError &&
                secondPasswordError(values.passwordOne, values.passwordTwo)}
              <Button
                type="submit"
                disabled={isSubmiting}
                style={{
                  marginTop: "20px",
                  background: "rgba(29,115,173, 0.8)",
                  width: matches ? "80%" : "60%",
                  marginLeft: matches ? "10%" : "20%",
                  marginRight: matches ? "10%" : "20%",
                  borderRadius: "20px",
                  textTransform: "none",
                  fontSize: matches ? "1.2em" : "1.4em"
                }}
                size={matches ? "medium" : "large"}
                variant="contained"
                color="primary"
              >
                {registerBank[id]}
              </Button>
              {localError && <FormError Tag={"h2"} message={correctBank[id]} />}
              {error && <FormError Tag={"h2"} message={failedBank[id]} />}
            </form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};
export default SignUpForm;
