import { combineReducers } from "redux";

import snackbar from "./snackbar";
import language from "./language";
import user from "./user";
import rankings from "./rankings";
import record from "./record";
import points from "./points";

export default combineReducers({
  snackbar,
  language,
  user,
  rankings,
  record,
  points
});
