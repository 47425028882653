import { put, call, all, takeLatest } from "redux-saga/effects";
import decode from "jwt-decode";

import {
  VERIFY_USER,
  VERIFY_USER_FAILED,
  VERIFY_USER_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  SUBMIT_PASSWORD,
  SUBMIT_PASSWORD_SUCCESS,
  SUBMIT_PASSWORD_FAILED,
  CREATE_USER,
  CREATE_USER_FAILED,
  CREATE_USER_SUCCESS
} from "../actionTypes/user";
import { SHOW_SNACKBAR } from "../actionTypes/snackbar";

import { RESPONSE_STATUS, postNoTokenRequest } from "../../common/ApiRequests";

const emailError = [
  "Email couldn't be found",
  "Еmail не найден",
  "Email nebuvo rastas",
  "Email wurde nicht gefunden"
];
const nicknameOrEmailError = [
  "Nickname or Email already in use",
  "Email или Nickname уже используются",
  "Email arba Nickname jau yra naudojami",
  "Nickname oder Email werden schon benutzt"
];
const serverError = [
  "Failed to connect",
  "Не удалось подключится",
  "Nepavyko prisijungti",
  "Verbindung fehlgeschlagen"
];
const dataError = [
  "Provided data was incorrect",
  "Недействительные данные",
  "Neteisingi duoemnys",
  "Falsche Datei"
];
function* verifyUser(action) {
  const { status, data } = yield postNoTokenRequest(
    "authenticate",
    action.user
  );
  switch (status) {
    case RESPONSE_STATUS.OK:
      localStorage.setItem("token", data.token);
      yield put({ type: VERIFY_USER_SUCCESS, user: decode(data.token) });
      yield call(action.callback);
      break;
    case RESPONSE_STATUS.BAD_RESPONSE:
      yield put({
        type: SHOW_SNACKBAR,
        message: dataError[action.id]
      });
      yield put({ type: VERIFY_USER_FAILED });
      break;
    case RESPONSE_STATUS.NO_CONNECTION:
      yield put({
        type: SHOW_SNACKBAR,
        message: serverError[action.id]
      });
      yield put({ type: VERIFY_USER_FAILED });
      break;
    default:
      yield put({ type: VERIFY_USER_FAILED });
  }
}

function* verifyEmail(action) {
  const { status } = yield postNoTokenRequest("verify-email", {
    email: action.email
  });
  switch (status) {
    case RESPONSE_STATUS.OK:
      yield put({ type: VERIFY_EMAIL_SUCCESS });
      break;
    case RESPONSE_STATUS.BAD_RESPONSE:
      yield put({
        type: SHOW_SNACKBAR,
        message: emailError[action.id]
      });
      yield put({ type: VERIFY_EMAIL_FAILED });
      break;
    case RESPONSE_STATUS.NO_CONNECTION:
      yield put({
        type: SHOW_SNACKBAR,
        message: serverError[action.id]
      });
      yield put({ type: VERIFY_EMAIL_FAILED });
      break;
    default:
      yield put({ type: VERIFY_EMAIL_FAILED });
  }
}

function* submitPassword(action) {
  const { status } = yield postNoTokenRequest(
    `submit-password/${action.userCode}`,
    {
      password: action.password
    }
  );
  switch (status) {
    case RESPONSE_STATUS.OK:
      yield put({ type: SUBMIT_PASSWORD_SUCCESS });
      yield call(action.callback);
      break;
    default:
      yield put({ type: SUBMIT_PASSWORD_FAILED });
  }
}

function* createUser(action) {
  const { status, data } = yield postNoTokenRequest("register", action.user);
  switch (status) {
    case RESPONSE_STATUS.OK:
      localStorage.setItem("token", data.token);
      yield put({ type: CREATE_USER_SUCCESS, user: decode(data.token) });
      yield call(action.callback);
      break;
    case RESPONSE_STATUS.BAD_RESPONSE:
      yield put({
        type: SHOW_SNACKBAR,
        message: nicknameOrEmailError[action.id]
      });
      yield put({ type: CREATE_USER_FAILED });
      break;
    case RESPONSE_STATUS.NO_CONNECTION:
      yield put({
        type: SHOW_SNACKBAR,
        message: serverError[action.id]
      });
      yield put({ type: CREATE_USER_FAILED });
      break;
    default:
      yield put({ type: CREATE_USER_FAILED });
  }
}

function* actionWatcher() {
  yield takeLatest(VERIFY_USER, verifyUser);
  yield takeLatest(VERIFY_EMAIL, verifyEmail);
  yield takeLatest(SUBMIT_PASSWORD, submitPassword);
  yield takeLatest(CREATE_USER, createUser);
}

export function* userSaga() {
  yield all([actionWatcher()]);
}
