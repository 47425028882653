import { put, all, takeLatest } from "redux-saga/effects";

import {
  GET_POINTS,
  GET_POINTS_FAILED,
  GET_POINTS_SUCCESS,
  POST_POINTS,
  POST_POINTS_SUCCESS,
  POST_POINTS_FAILED
} from "../actionTypes/points";

import {
  RESPONSE_STATUS,
  getRequest,
  postRequest
} from "../../common/ApiRequests";

function* getPoints() {
  const { status, data } = yield getRequest(`points`);
  switch (status) {
    case RESPONSE_STATUS.OK:
      yield put({ type: GET_POINTS_SUCCESS, points: data.points });
      break;
    default:
      yield put({ type: GET_POINTS_FAILED });
  }
}

function* postPoints(action) {
  const obj = { points: action.points };
  const { status } = yield postRequest(`points`, obj);
  switch (status) {
    case RESPONSE_STATUS.OK:
      yield put({ type: POST_POINTS_SUCCESS, points: action.points });
      break;
    default:
      yield put({ type: POST_POINTS_FAILED });
  }
}

function* actionWatcher() {
  yield takeLatest(GET_POINTS, getPoints);
  yield takeLatest(POST_POINTS, postPoints);
}

export function* pointsSaga() {
  yield all([actionWatcher()]);
}
