import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Card, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import NinethGame from "./NinethGame";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "0px 20px 20px 20px",
    background: "rgba(255, 255, 255, 0.8)"
  }
});
const NinethGameLayout = () => {
  const classes = useStyles();
  const user = useSelector(store => store.user.user);
  const history = useHistory();

  React.useEffect(() => {
    if (!user) {
      history.push("/sign-in");
    }
  }, [user, history]);

  return (
    <Container maxWidth="md">
      <Card className={classes.card} elevation={4}>
        <h1 style={{ textAlign: "center", width: "100%" }}>Number agility</h1>
        <NinethGame />
      </Card>
    </Container>
  );
};
export default NinethGameLayout;
