import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import GridItem from "./GridItem";
import maincompare from "../common/main/maincompare.jpg";
import mainmaths from "../common/main/mainmaths.jpg";

const MathsList = () => {
  const id = useSelector(store => store.language.id);
  const user = useSelector(store => store.user.user);
  const level = useSelector(store => store.points.level);
  const history = useHistory();

  React.useEffect(() => {
    if (!user) {
      history.push("/sign-in");
    }
  }, [user, history]);

  const linkData = [
    "/games/compare",
    "/games/correctly",
    "/games/mentalcounting",
    "/games/numberagility",
    "/games/simplecounting"
  ];
  const nameData = [
    "Compare",
    "Correctly?",
    "Mental counting",
    "Number agility",
    "Simple counting"
  ];
  const imageBank = [maincompare, maincompare, mainmaths, mainmaths, mainmaths];
  const titleBank = [
    "Math games",
    "Математические игры",
    "Matematini žaidimai",
    "Mathe-Spiele"
  ];
  const descriptionData = [
    [
      "Compare appeared equations",
      "Сравните появившиеся уравнения",
      "Palyginkite pasirodžiusias lygtis",
      "Vergleichen Sie angezeigte Gleichungen"
    ],
    [
      "Is appeared maths operation true ?",
      "Правильна ли появившаяся математическая операция ?",
      "Ar pasirodusi matematikos operacija teisinga ?",
      "Ist die angezeigte Matheoperation wahr ?"
    ],
    [
      "Count every operation and at the end submit the result",
      "Подсчитайте каждую операцию и в конце отправьте результат",
      "Apskaičiuokite kiekvieną operaciją ir pabaigoje pateikite rezultatą",
      "Zählen Sie jede Operation und geben Sie am Ende das Ergebnis ein"
    ],
    [
      "Select numbers in ascending order and then their sum",
      "Выберите числа в порядке возрастания, а затем их сумму",
      "Pasirinkite skaičius didėjančia tvarka ir tada jų sumą",
      "Wählen Sie die Zahlen in aufsteigender Reihenfolge und dann ihre Summe"
    ],
    [
      "Count answer of appeared equation",
      "Подсчитайте ответ появившегося уравнения",
      "Suskaičiuokite lygties atsakymą",
      "Zählen Sie Antwort der erscheinenden Gleichung"
    ]
  ];
  const hardnessData = ["HARD", "MID", "MID", "HARD", "EASY"];
  return (
    <Container maxWidth="md">
      <h1 style={{ textAlign: "center", color: "rgba(255, 255, 255, 0.9)" }}>
        {titleBank[id]}
      </h1>
      <Grid container spacing={1}>
        {linkData
          .filter(item => {
            const hardness = hardnessData[linkData.indexOf(item)];
            if (hardness === "EASY") {
              return true;
            } else if (hardness === "MID" && level > 5) {
              return true;
            } else if (level >= 10) {
              return true;
            }
            return false;
          })
          .map(item => {
            return (
              <GridItem
                name={nameData[linkData.indexOf(item)]}
                hardness={hardnessData[linkData.indexOf(item)]}
                description={descriptionData[linkData.indexOf(item)][id]}
                image={imageBank[linkData.indexOf(item)]}
                link={item}
                key={linkData.indexOf(item)}
              />
            );
          })}
      </Grid>
    </Container>
  );
};
export default MathsList;
