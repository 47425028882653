import {
  GET_RANKINGS,
  GET_RANKINGS_SUCCESS,
  GET_RANKINGS_FAILED
} from "../actionTypes/rankings";

const initialState = {
  items: [{ name: "Nickname", score: 1000000 }],
  error: undefined
};

export default function rankingsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_RANKINGS: {
      return {
        ...state,
        error: undefined,
        items: [{ name: "Nickname", score: 1000000 }]
      };
    }
    case GET_RANKINGS_SUCCESS: {
      const items = [{ name: "Nickname", score: 1000000 }];
      const newItems = [...items, ...action.items];
      return { ...state, items: newItems };
    }
    case GET_RANKINGS_FAILED: {
      return {
        ...state,
        error: true
      };
    }
    default: {
      return state;
    }
  }
}
