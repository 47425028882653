import React from "react";
import { useSelector } from "react-redux";

import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(color => ({
  card: {
    margin: "20px 0px",
    padding: "0px 20px 20px 20px",
    background: "rgba(255, 255, 255, 0.8)"
  },
  container: {
    width: "70%",
    display: "flex",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto"
  },
  level: {
    width: "80px",
    height: "80px",
    borderRadius: "40px",
    borderWidth: "4px",
    borderStyle: "solid",
    marginTop: "0px",
    marginBottom: "0px",
    float: "left",
    fontSize: "2.1em",
    textAlign: "center",
    lineHeight: "72px",
    color: "white",
    background: "black"
  },
  bar: {
    width: "calc(100% - 80px)",
    float: "left",
    display: "flex",
    alignItems: "center"
  }
}));

const LevelView = () => {
  const points = useSelector(store => store.points.points);
  const level = useSelector(store => store.points.level);
  const id = useSelector(store => store.language.id);
  const classes = useStyles();
  const color = level >= 10 ? "#ff0000" : level > 5 ? "#0000ff" : "#00ff00";

  const levelBank = ["Level", "Уровень", "Lygis", "Stufe"];
  const BorderLinearProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: lighten(color, 0.5)
    },
    bar: {
      borderRadius: 20,
      backgroundColor: color
    }
  })(LinearProgress);

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>{levelBank[id]}</h2>
      <div className={classes.container}>
        <p
          className={classes.level}
          style={{
            borderColor: color
          }}
        >
          {`${level}`}
        </p>
        <div className={classes.bar}>
          <BorderLinearProgress
            style={{ width: "100%" }}
            variant="determinate"
            value={level >= 10 ? 100 : (points * 100) / 3000}
            color="secondary"
          />
        </div>
      </div>
    </div>
  );
};

export default LevelView;
