import React from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

const useStyles = makeStyles({
  button: {
    float: "left",
    fontWeight: "bold",
    background: "black",
    color: "white",
    width: "25%",
    minWidth: "30px",
    borderColor: "white",
    borderStyle: "solid",
    borderWidth: "1px"
  },
  submitButton: {
    width: "calc(100% - 10px)",
    margin: "5px",
    background: "green",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  }
});

const KeyboardLayout = ({ isDisabled, submit }) => {
  const classes = useStyles();
  const submitBank = ["ANSWER", "ОТВЕТИТЬ", "ATSAKYTI", "ANTWORTEN"];
  const id = useSelector(store => store.language.id);
  const [theAnswer, setTheAnswer] = React.useState(0);

  const onePushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 1;
      });
    }
  };

  const twoPushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 2;
      });
    }
  };

  const threePushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 3;
      });
    }
  };

  const fourPushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 4;
      });
    }
  };

  const fivePushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 5;
      });
    }
  };

  const sixPushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 6;
      });
    }
  };

  const sevenPushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 7;
      });
    }
  };

  const eightPushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 8;
      });
    }
  };

  const ninePushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10 + 9;
      });
    }
  };

  const zeroPushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue * 10;
      });
    }
  };

  const clearPushed = () => {
    if (isDisabled) {
      setTheAnswer(0);
    }
  };

  const deletePushed = () => {
    if (isDisabled) {
      setTheAnswer(oldValue => {
        return oldValue % 10;
      });
    }
  };

  return (
    <div
      style={{
        display: "inline-block",
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        borderColor: "black",
        borderStyle: "solid",
        borderWidth: "1px"
      }}
    >
      <div>
        <Typography
          variant="body1"
          style={{ fontSize: "1.4em", fontWeight: "bold" }}
        >
          {theAnswer}
        </Typography>
      </div>
      <div>
        <Button className={classes.button} onClick={ninePushed}>
          9
        </Button>
        <Button className={classes.button} onClick={eightPushed}>
          8
        </Button>
        <Button className={classes.button} onClick={sevenPushed}>
          7
        </Button>
        <Button className={classes.button} onClick={zeroPushed}>
          0
        </Button>
      </div>
      <div>
        <Button className={classes.button} onClick={sixPushed}>
          6
        </Button>
        <Button className={classes.button} onClick={fivePushed}>
          5
        </Button>
        <Button className={classes.button} onClick={fourPushed}>
          4
        </Button>
        <Button className={classes.button} onClick={deletePushed}>
          {"<"}
        </Button>
      </div>
      <div>
        <Button className={classes.button} onClick={threePushed}>
          3
        </Button>
        <Button className={classes.button} onClick={twoPushed}>
          2
        </Button>
        <Button className={classes.button} onClick={onePushed}>
          1
        </Button>
        <Button className={classes.button} onClick={clearPushed}>
          C
        </Button>
      </div>
      <div>
        <Button
          className={classes.submitButton}
          size="medium"
          variant="contained"
          type="submit"
          color="primary"
          style={{ marginTop: "0px" }}
          disabled={!isDisabled}
          onClick={e => {
            e.preventDefault();
            submit(theAnswer);
          }}
        >
          {submitBank[id]}
        </Button>
      </div>
    </div>
  );
};
KeyboardLayout.propTypes = {
  isDisabled: PropTypes.bool,
  submit: PropTypes.func
};
export default KeyboardLayout;
