import { all, fork } from "redux-saga/effects";

import { userSaga } from "./userSaga";
import { rankingsSaga } from "./rankingsSaga";
import { recordSaga } from "./recordSaga";
import { pointsSaga } from "./pointsSaga";

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(rankingsSaga),
    fork(pointsSaga),
    fork(recordSaga)
  ]);
}
