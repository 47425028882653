import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import LevelView from "./LevelView";

import { getRecord, setZeroRecord } from "../../state/actions/record";
import { getPoints } from "../../state/actions/points";

const useStyles = makeStyles({
  leftText: {
    float: "left",
    width: "50%",
    textAlign: "right",
    padding: "5px",
    fontSize: "1.4em"
  },
  rightText: {
    float: "right",
    width: "50%",
    textAlign: "left",
    padding: "5px",
    fontSize: "1.4em"
  },
  rightBlock: {
    display: "inline-block",
    float: "right",
    width: "50%",
    padding: "5px"
  },
  icon: {
    marginRight: "5px",
    color: "red"
  }
});
const UserStats = ({
  points,
  showFirstHeart,
  showSecondHeart,
  showThirdHeart,
  gameId
}) => {
  const id = useSelector(store => store.language.id);
  const dispatch = useDispatch();
  const record = useSelector(store => store.record.score);
  const [isFirstTime, setFirstTime] = React.useState(true);
  const level = useSelector(store => store.points.level);
  const classes = useStyles();

  const recordBank = ["Record:", "Рекорд:", "Rekordas:", "Rekord:"];
  const lifeBank = ["Lifes:", "Жизни:", "Gyvybės:", "Leben:"];
  const pointsBank = ["Points:", "Очки:", "Punktai:", "Punkten:"];

  React.useEffect(() => {
    if (isFirstTime) {
      setFirstTime(false);
      if (level === 10) {
        dispatch(getRecord(gameId));
      } else {
        dispatch(setZeroRecord());
      }
      dispatch(getPoints());
    }
  }, [isFirstTime, gameId, dispatch, level]);

  return (
    <div>
      <LevelView />
      {level >= 10 && (
        <div>
          <Typography className={classes.leftText} variant="body1">
            {recordBank[id]}
          </Typography>
          <Typography className={classes.rightText} variant="body1">
            {record}
          </Typography>
        </div>
      )}
      <Typography className={classes.leftText} variant="body1">
        {lifeBank[id]}
      </Typography>
      <div className={classes.rightBlock}>
        {showFirstHeart && <FavoriteIcon className={classes.icon} />}
        {showSecondHeart && <FavoriteIcon className={classes.icon} />}
        {showThirdHeart && <FavoriteIcon className={classes.icon} />}
      </div>
      <div style={{ display: "inline-block", width: "100%" }}>
        <Typography className={classes.leftText} variant="body1">
          {pointsBank[id]}
        </Typography>
        <Typography className={classes.rightText} variant="body1">
          {points}
        </Typography>
      </div>
    </div>
  );
};
UserStats.propTypes = {
  points: PropTypes.number.isRequired,
  gameId: PropTypes.number.isRequired,
  showFirstHeart: PropTypes.bool.isRequired,
  showSecondHeart: PropTypes.bool.isRequired,
  showThirdHeart: PropTypes.bool.isRequired
};
export default UserStats;
