import { put, all, takeLatest } from "redux-saga/effects";

import {
  GET_RANKINGS,
  GET_RANKINGS_FAILED,
  GET_RANKINGS_SUCCESS
} from "../actionTypes/rankings";

import { RESPONSE_STATUS, getRequest } from "../../common/ApiRequests";

function* getRankings(action) {
  const { status, data } = yield getRequest(`rankings/${action.id}`);
  switch (status) {
    case RESPONSE_STATUS.OK:
      yield put({ type: GET_RANKINGS_SUCCESS, items: data });
      break;
    default:
      yield put({ type: GET_RANKINGS_FAILED });
  }
}

function* actionWatcher() {
  yield takeLatest(GET_RANKINGS, getRankings);
}

export function* rankingsSaga() {
  yield all([actionWatcher()]);
}
