import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  leftButton: {
    marginLeft: "20px",
    height: "55px",
    color: "#ffffff",
    fontSize: "2em",
    float: "left",
    textTransform: "none",
    borderRadius: "5px",
    borderColor: "#FFFFFF",
    borderWidth: "1px",
    borderStyle: "solid",
    "&:hover": {
      color: "#1D73AD",
      background: "black"
    },
    "&:focus": {
      color: "#1D73AD"
    }
  },
  userBar: {
    height: "85px",
    background: "rgba(171, 190, 231, 0.3)",
    display: "block",
    width: "100%",
    padding: "15px 100px 15px 100px"
  }
});

const UserNavBar = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:710px)");

  return (
    <div
      className={classes.userBar}
      style={{
        paddingLeft: matches ? "0px" : "100px"
      }}
    >
      <Link to="/">
        <Button className={classes.leftButton}>
          <HomeIcon />
        </Button>
      </Link>
    </div>
  );
};
export default UserNavBar;
