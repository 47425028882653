import { GET_POINTS_SUCCESS, POST_POINTS_SUCCESS } from "../actionTypes/points";

const initialState = {
  points: 0,
  level: 1
};

export default function languageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_POINTS_SUCCESS: {
      const p = action.points % 3000;
      let l = Math.floor(action.points / 3000) + 1;
      if (l > 10) {
        l = 10;
      }
      return { ...state, points: p, level: l };
    }
    case POST_POINTS_SUCCESS: {
      const newAllP = state.points + action.points;
      const newP = newAllP % 3000;
      let newL = state.level + Math.floor(newAllP / 3000);
      if (newL > 10) {
        newL = 10;
      }
      return { ...state, points: newP, level: newL };
    }
    default: {
      return state;
    }
  }
}
