import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import GridItem from "./GridItem";
import memorymain from "../common/main/memorymain.jpg";
import mainphotofigures from "../common/main/mainphotofigures.png";

const MemoryList = () => {
  const id = useSelector(store => store.language.id);
  const user = useSelector(store => store.user.user);
  const level = useSelector(store => store.points.level);
  const history = useHistory();

  React.useEffect(() => {
    if (!user) {
      history.push("/sign-in");
    }
  }, [user, history]);

  const linkData = [
    "/games/justremember",
    "/games/sharpeyes",
    "/games/remembernumber",
    "/games/markthelast",
    "/games/repeatthesequence",
    "/games/likeprevious"
  ];
  const imageData = [
    memorymain,
    mainphotofigures,
    memorymain,
    memorymain,
    memorymain,
    memorymain
  ];
  const nameData = [
    "Just Remember",
    "Sharp eyes",
    "Remember the number",
    "Mark the last",
    "Sequence",
    "Remember previous"
  ];
  const descriptionData = [
    [
      "Click on all colored figures after they disappear",
      "Нажмите на все закрашенные фигуры после того как они пропадут",
      "Paspauskite ant visų dažytų figurų po to kai jie pradings",
      "Klicken Sie auf alle gefärbte Figuren, nachdem sie verschwunden haben"
    ],
    [
      "Are figures in black squares the same?",
      "Одинаковы ли фигуры в черных квадратах?",
      "Ar figuros juodose kvadratuose yra vienodi?",
      "Sind die Figuren in schwarzen Quadraten gleich?"
    ],
    [
      "Repeat the appeared number",
      "Повторите появившиеся число",
      "Pakartokite pasirodžiusį numerį",
      "Wiederholen Sie die angezeigte Nummer"
    ],
    [
      "Mark last appeared square",
      "Отметьте последний появившийся квадрат",
      "Pažymėkite paskutinį pasirodžiusį kvadratą",
      "Markieren Sie das zuletzt erschiene Quadrat"
    ],
    [
      "Repeat the sequence of appeared squares",
      "Повторите последовательность появившихся квадратов",
      "Pakartokite pasirodžiusių kvadratų seką",
      "Wiederholen Sie die Reihenfolge der angezeigten Quadrate"
    ],
    [
      "Is current figure same as the last one ?",
      "Соответствует ли текущая фигура предыдущей ?",
      "Ar dabartinė figura sutampa su praeita ?",
      "Stimmt die aktuelle Figur mit der letzten überein?"
    ]
  ];
  const hardnessData = ["MID", "MID", "EASY", "HARD", "HARD", "EASY"];
  const titleBank = [
    "Memory games",
    "Игры на память",
    "Atminties žaidimai",
    "Spiele für Gedächtnis"
  ];
  return (
    <Container maxWidth="md">
      <h1 style={{ textAlign: "center", color: "rgba(255, 255, 255, 0.9)" }}>
        {titleBank[id]}
      </h1>
      <Grid container spacing={2}>
        {linkData
          .filter(item => {
            const hardness = hardnessData[linkData.indexOf(item)];
            if (hardness === "EASY") {
              return true;
            } else if (hardness === "MID" && level > 5) {
              return true;
            } else if (level >= 10) {
              return true;
            }
            return false;
          })
          .map(item => {
            return (
              <GridItem
                name={nameData[linkData.indexOf(item)]}
                hardness={hardnessData[linkData.indexOf(item)]}
                description={descriptionData[linkData.indexOf(item)][id]}
                image={imageData[linkData.indexOf(item)]}
                link={item}
                key={linkData.indexOf(item)}
              />
            );
          })}
      </Grid>
    </Container>
  );
};
export default MemoryList;
