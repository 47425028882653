import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import GridItem from "./GridItem";
import letternumbermain from "../common/main/letternumbermain.jpg";
import maincolors from "../common/main/maincolors.png";
import maincolorsandshapes from "../common/main/maincolorsandshapes.png";

const AgilityList = () => {
  const id = useSelector(store => store.language.id);
  const user = useSelector(store => store.user.user);
  const level = useSelector(store => store.points.level);
  const history = useHistory();

  React.useEffect(() => {
    if (!user) {
      history.push("/sign-in");
    }
  }, [user, history]);

  const linkData = [
    "/games/risingcolors",
    "/games/letternumber",
    "/games/singlecolor",
    "/games/shapecolor"
  ];
  const imageData = [
    maincolors,
    letternumbermain,
    maincolors,
    maincolorsandshapes
  ];
  const nameData = [
    "Rising Colors",
    "Letter/Number",
    "Single Name/Color",
    "Color of shape"
  ];
  const descriptionData = [
    [
      `Does COLOR of text on the left side match its NAME(TEXT) on the
  right side?`,
      `Совпадает ли ЦВЕТ левого текста с ИМЕНЕМ(ТЕКСТОМ) с правой стороны?`,
      `Ar sutampa kairio teksto SPALVA su jos PAVADINIMU(TEKSTU) dešinėje pusėje?`,
      `Passt die FARBE vom linken Text zu ihrem NAMEN(TEXT) auf der rechten Seite?`
    ],
    [
      "Select appropriate type of appeared number or letter",
      "Выберите подходящий тип появившейся цифры либо буквы",
      "Pasirinkite tinkamą pasirodančio numerio arba raidės tipą",
      "Wählen Sie den passenden Typ der aufgetauchten Buchstabe oder Nummer"
    ],
    [
      "Are color and meaning of word same ?",
      "Одинаковы ли цвет и значение слова ?",
      "Ar žodžio spalva ir reikšmė yra ta pati ?",
      "Sind Farbe und Wortbedeutung gleich ?"
    ],
    [
      "Are COLOR of TEXT and COLOR of shape the same?",
      "Одинаковы ли ЦВЕТ текста и ЦВЕТ фигуры?",
      "Ar teksto SPALVA ir figuros SPALVA yra ta pati?",
      "Sind FARBE vom Text und FARBE der Figur gleich?"
    ]
  ];
  const hardnessData = ["HARD", "MID", "EASY", "EASY"];
  const titleBank = [
    "Agility games",
    "Игры на пластичность",
    "Judrumo žaidimai",
    "Spiele für Geschicklichkeit"
  ];

  return (
    <Container maxWidth="md">
      <h1 style={{ textAlign: "center", color: "rgba(255, 255, 255, 0.9)" }}>
        {titleBank[id]}
      </h1>
      <Grid container spacing={1}>
        {linkData
          .filter(item => {
            const hardness = hardnessData[linkData.indexOf(item)];
            if (hardness === "EASY") {
              return true;
            } else if (hardness === "MID" && level > 5) {
              return true;
            } else if (level >= 10) {
              return true;
            }
            return false;
          })
          .map(item => {
            return (
              <GridItem
                name={nameData[linkData.indexOf(item)]}
                hardness={hardnessData[linkData.indexOf(item)]}
                description={descriptionData[linkData.indexOf(item)][id]}
                image={imageData[linkData.indexOf(item)]}
                link={item}
                key={linkData.indexOf(item)}
              />
            );
          })}
      </Grid>
    </Container>
  );
};
export default AgilityList;
