import React from "react";
import "./App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import LagnuageBlock from "./components/nav-bars/LanguageBlock";
import UserNavBar from "./components/nav-bars/UserNavBar";
import FunctionsNavBar from "./components/nav-bars/FunctionsNavBar";
import AllList from "./components/GamesList/AllList";
import MathsList from "./components/GamesList/MathsList";
import MemoryList from "./components/GamesList/MemoryList";
import FirstGameLayout from "./components/FirstGame/FirstGameLayout";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import MySnackbar from "./components/common/MySnackbar";
import SecondGameLayout from "./components/SecondGame/SecondGameLayout";
import ThirdGameLayout from "./components/ThirdGame/ThirdGameLayout";
import FourthGameLayout from "./components/FourthGame/FourthGameLayout";
import FifthGameLayout from "./components/FifthGame/FifthGameLayout";
import SixthGameLayout from "./components/SixthGame/SixthGameLayout";
import SeventhGameLayout from "./components/SeventhGame/SeventhGameLayout";
import EighthGameLayout from "./components/EighthGame/EighthGameLayout";
import NinethGameLayout from "./components/NinethGame/NinethGameLayout";
import TenthGameLayout from "./components/TenthGame/TenthGameLayout";
import EleventhGameLayout from "./components/EleventhGame/EleventhGameLayout";
import TwelvethGameLayout from "./components/TwelvethGame/TwelvethGameLayout";
import ThirteenthGameLayout from "./components/ThirteenthGame/ThirteenthGameLayout";
import FourteenthGameLayout from "./components/FourteenthGame/FourteenthGameLayout";
import FifteenthGameLayout from "./components/FifteenthGame/FifteenthGameLayout";
import SignInForm from "./components/sign-in/SignInForm";
import SignUpForm from "./components/sign-up/SignUpForm";
import RestorePasswordForm from "./components/sign-in/RestorePasswordForm";
import background from "./BrainImageTwo.jpg";
import AgilityList from "./components/GamesList/AgilityList";
import uniquebrains from "./uniquebrains.png";
import RankingsPage from "./components/rankings/RankingsPage";
import RankingsList from "./components/rankings/RankingsList";
import Policy from "./components/policy/Policy";

function App() {
  const matches = useMediaQuery("(max-width:600px)");
  const matchesTwo = useMediaQuery("(max-width:450px)");
  return (
    <BrowserRouter>
      <CssBaseline />
      <img
        alt="background"
        src={background}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "-1"
        }}
      />
      <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
        <img
          alt="logo"
          src={uniquebrains}
          style={{
            height: matchesTwo ? "50px" : "80px",
            marginTop: matchesTwo ? "18px" : "8px",
            width: matches ? (matchesTwo ? "43%" : "50%") : ""
          }}
        />
      </div>
      <LagnuageBlock />
      <div
        style={{
          minHeight: "100vh",
          width: "100%",
          margin: "0px"
        }}
      >
        <UserNavBar />
        <FunctionsNavBar />
        <Switch>
          <Route exact path="/sign-in" component={SignInForm} />
          <Route
            exact
            path="/restore-password/:userCode"
            component={RestorePasswordForm}
          />
          <Route exact path="/sign-up" component={SignUpForm} />
          <Route exact path="/rankings" component={RankingsPage} />
          <Route exact path="/games/agility" component={AgilityList} />
          <Route exact path="/games/memory" component={MemoryList} />
          <Route exact path="/games/maths" component={MathsList} />
          <Route exact path="/games/all" component={AllList} />
          <Route exact path="/rankings/:id" component={RankingsList} />
          <Route exact path="/games/risingcolors" component={FirstGameLayout} />
          <Route
            exact
            path="/games/letternumber"
            component={SecondGameLayout}
          />
          <Route exact path="/games/justremember" component={ThirdGameLayout} />
          <Route exact path="/games/sharpeyes" component={FourthGameLayout} />
          <Route
            exact
            path="/games/remembernumber"
            component={FifthGameLayout}
          />
          <Route exact path="/policy" component={Policy} />
          <Route exact path="/games/compare" component={SixthGameLayout} />
          <Route exact path="/games/correctly" component={SeventhGameLayout} />
          <Route
            exact
            path="/games/mentalcounting"
            component={EighthGameLayout}
          />
          <Route
            exact
            path="/games/numberagility"
            component={NinethGameLayout}
          />
          <Route
            exact
            path="/games/simplecounting"
            component={TenthGameLayout}
          />
          <Route
            exact
            path="/games/singlecolor"
            component={TwelvethGameLayout}
          />
          <Route
            exact
            path="/games/markthelast"
            component={EleventhGameLayout}
          />
          <Route
            exact
            path="/games/repeatthesequence"
            component={ThirteenthGameLayout}
          />
          <Route
            exact
            path="/games/likeprevious"
            component={FourteenthGameLayout}
          />
          <Route
            exact
            path="/games/shapecolor"
            component={FifteenthGameLayout}
          />
          <Route path="/" component={Home} />
        </Switch>
      </div>
      <Footer />
      <MySnackbar />
    </BrowserRouter>
  );
}

export default App;
