import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Typography, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import UseArrowKeys from "../common/UseArrowKeys";
import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "20px"
  },
  middleText: {
    fontWeight: "bold",
    width: "100%",
    textAlign: "center"
  },
  leftText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    marginLeft: "auto",
    marginRight: "20px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  buttonBlock: {
    marginBottom: "15px",
    marginTop: "30px"
  },
  rightText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    marginRight: "auto",
    marginLeft: "20px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  block: {
    display: "flex",
    marginBottom: "15px",
    marginTop: "15px",
    width: "100%",
    padding: "5px"
  },
  mainBlock: {
    display: "inline-block",
    width: "50%",
    margin: "10px 40px 10px 40px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  leftButton: {
    width: "25%",
    margin: "5px 20px 5px 0px",
    fontSize: "1.4em",
    background: "red",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  rightButton: {
    width: "25%",
    margin: "5px 0px 5px 20px",
    fontSize: "1.4em",
    background: "green",
    "&:hover": {
      background: "black"
    }
  },
  beginButton: {
    width: "20%",
    margin: "20px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  }
});

const SecondGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(4);
  const evenAnswerBank = ["EVEN", "ЧЁТНОЕ", "LYGINIS", "GERADE"];
  const unevenAnswerBank = ["ODD", "НЕЧЁТНОЕ", "NELYGINIS", "UNGERADE"];
  const vowelAnswerBank = ["VOWEL", "ГЛАСНАЯ", "BALSIS", "VOKAL"];
  const consonantAnswerBank = [
    "CONSONANT",
    "СОГЛАСНАЯ",
    "PRIEBALSIS",
    "KONSONANT"
  ];
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [leftButtonText, setLeftButtonText] = React.useState(
    evenAnswerBank[id]
  );
  const [rightButtonText, setRightButtonText] = React.useState(
    unevenAnswerBank[id]
  );
  const [leftNumberText, setLeftNumberText] = React.useState(
    evenAnswerBank[id]
  );
  const [rightNumberText, setRightNumberText] = React.useState(
    unevenAnswerBank[id]
  );
  const [leftLetterText, setLeftLetterText] = React.useState(
    consonantAnswerBank[id]
  );
  const [rightLetterText, setRightLetterText] = React.useState(
    vowelAnswerBank[id]
  );
  const [answerText, setAnswerText] = React.useState("2A");
  const [backgroundOne, setBackgroundOne] = React.useState("blue");
  const [backgroundTwo, setBackgroundTwo] = React.useState("#48505e");
  const [isEvenType, setEvenType] = React.useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:500px)");
  const evenNumberBank = ["0", "2", "4", "6", "8"];
  const unevenNumberBank = ["1", "3", "5", "7", "9"];
  const vowelLetterBank = ["A", "E", "I", "J", "O", "Q", "U", "Y"];
  const consonantLetterBank = [
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "K",
    "L",
    "M",
    "N",
    "P",
    "R",
    "S",
    "T",
    "V",
    "W",
    "X",
    "Z"
  ];
  const allLetterBank = [
    "A",
    "E",
    "I",
    "J",
    "O",
    "Q",
    "U",
    "Y",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "K",
    "L",
    "M",
    "N",
    "P",
    "R",
    "S",
    "T",
    "V",
    "W",
    "X",
    "Z"
  ];

  const allNumberBank = ["0", "2", "4", "6", "8", "1", "3", "5", "7", "9"];

  const instructionsBank = [
    "Select appropriate type of appeared number or letter",
    "Выберите подходящий тип появившейся цифры либо буквы",
    "Pasirinkite tinkamą pasirodančio numerio arba raidės tipą",
    "Wählen Sie den passenden Typ der aufgetauchten Buchstabe oder Nummer"
  ];
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];

  const checkKey = e => {
    if (e.keyCode === 37) {
      falsePressed();
    } else if (e.keyCode === 39) {
      truePressed();
    }
  };

  React.useEffect(() => {
    document.onkeydown = checkKey;
    return () => {
      document.onkeydown = undefined;
    };
  });

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "gamesdjalkda902jd29dadsa"
    );
    dispatch(postRecord(2, encryptedAES.toString()));
  };

  const postNewPoints = points => {
    dispatch(postPoints(points));
  };

  const beginGame = () => {
    setLevel(1);
    setMistakes(0);
    setPoints(0);
    setCounter(4);
    setGameRunning(true);
    generateGame();
  };

  const generateGame = () => {
    if (Math.random() * 100 > 50) {
      setEvenType(true);
      setBackgroundOne("blue");
      setBackgroundTwo("#48505e");
      setLeftNumberText(evenAnswerBank[id]);
      setRightNumberText(unevenAnswerBank[id]);
      setLeftLetterText("");
      setRightLetterText("");
      setLeftButtonText(evenAnswerBank[id]);
      setRightButtonText(unevenAnswerBank[id]);
      generateFirstAnswer(true);
    } else {
      setEvenType(false);
      setBackgroundOne("#48505e");
      setBackgroundTwo("blue");
      setLeftButtonText(consonantAnswerBank[id]);
      setRightButtonText(vowelAnswerBank[id]);
      setLeftNumberText("");
      setRightNumberText("");
      setLeftLetterText(consonantAnswerBank[id]);
      setRightLetterText(vowelAnswerBank[id]);
      generateFirstAnswer(false);
    }
  };

  const generateFirstAnswer = type => {
    if (level > 30) {
      setCounter(10);
    } else if (level > 20) {
      setCounter(7);
    } else if (level > 10) {
      setCounter(5);
    }
    if (Math.random() * 100 > 50) {
      setAnswer(true);
      generateValue(type, true);
    } else {
      setAnswer(false);
      generateValue(type, false);
    }
  };

  const generateAnswer = () => {
    if (Math.random() * 100 > 50) {
      setAnswer(true);
      generateValue(isEvenType, true);
    } else {
      setAnswer(false);
      generateValue(isEvenType, false);
    }
  };

  const generateValue = (type, myAnswer) => {
    if (type) {
      generateNumberAnswer(myAnswer);
    } else {
      generateLetterAnswer(myAnswer);
    }
  };

  const generateLetterAnswer = myAnswer => {
    if (myAnswer) {
      if (Math.random() * 10 < 5) {
        setAnswerText(
          vowelLetterBank[
            Math.round(Math.random() * (vowelLetterBank.length - 1))
          ] +
            allNumberBank[
              Math.round(Math.random() * (allNumberBank.length - 1))
            ]
        );
        beginCountdown();
      } else {
        setAnswerText(
          allNumberBank[
            Math.round(Math.random() * (allNumberBank.length - 1))
          ] +
            vowelLetterBank[
              Math.round(Math.random() * (vowelLetterBank.length - 1))
            ]
        );
        beginCountdown();
      }
    } else {
      if (Math.random() * 10 < 5) {
        setAnswerText(
          consonantLetterBank[
            Math.round(Math.random() * (consonantLetterBank.length - 1))
          ] +
            allNumberBank[
              Math.round(Math.random() * (allNumberBank.length - 1))
            ]
        );
        beginCountdown();
      } else {
        setAnswerText(
          allNumberBank[
            Math.round(Math.random() * (allNumberBank.length - 1))
          ] +
            consonantLetterBank[
              Math.round(Math.random() * (consonantLetterBank.length - 1))
            ]
        );
        beginCountdown();
      }
    }
  };

  const generateNumberAnswer = myAnswer => {
    if (myAnswer) {
      if (Math.random() * 10 < 5) {
        setAnswerText(
          unevenNumberBank[
            Math.round(Math.random() * (unevenNumberBank.length - 1))
          ] +
            allLetterBank[
              Math.round(Math.random() * (allLetterBank.length - 1))
            ]
        );
        beginCountdown();
      } else {
        setAnswerText(
          allLetterBank[
            Math.round(Math.random() * (allLetterBank.length - 1))
          ] +
            unevenNumberBank[
              Math.round(Math.random() * (unevenNumberBank.length - 1))
            ]
        );
        beginCountdown();
      }
    } else {
      if (Math.random() * 10 < 5) {
        setAnswerText(
          evenNumberBank[
            Math.round(Math.random() * (evenNumberBank.length - 1))
          ] +
            allLetterBank[
              Math.round(Math.random() * (allLetterBank.length - 1))
            ]
        );
        beginCountdown();
      } else {
        setAnswerText(
          allLetterBank[
            Math.round(Math.random() * (allLetterBank.length - 1))
          ] +
            evenNumberBank[
              Math.round(Math.random() * (evenNumberBank.length - 1))
            ]
        );
        beginCountdown();
      }
    }
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const beginCountdown = () => {
    setIsAnswering(true);
  };

  const truePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          if (level < 10) {
            generateAnswer();
          } else {
            generateGame();
          }
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const falsePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (!answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          if (level < 10) {
            generateAnswer();
          } else {
            generateGame();
          }
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const mistakeMade = () => {
    if (level > 1) {
      setLevel(1);
      setCounter(4);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        if (points - 50 > 0) {
          postNewPoints(points - 50);
        }
      }
    } else {
      setTimeout(generateAnswer, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={2}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionsBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}
        <div className={classes.block}>
          <Typography
            className={classes.leftText}
            style={{
              background: backgroundOne,
              width: matches ? "35%" : "25%",
              fontSize: matches ? "0.8em" : "1.4em",
              color: "white"
            }}
            variant="body1"
          >
            {leftNumberText}
          </Typography>
          <Typography
            className={classes.rightText}
            style={{
              background: backgroundOne,
              width: matches ? "35%" : "25%",
              fontSize: matches ? "0.8em" : "1.4em",
              color: "white"
            }}
            variant="body1"
          >
            {rightNumberText}
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography
            className={classes.middleText}
            style={{
              fontSize: matches ? "1.6em" : "2.8em"
            }}
            variant="body1"
          >
            {answerText}
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography
            className={classes.leftText}
            style={{
              background: backgroundTwo,
              width: matches ? "35%" : "25%",
              fontSize: matches ? "0.8em" : "1.4em",
              color: "white"
            }}
            variant="body1"
          >
            {leftLetterText}
          </Typography>
          <Typography
            className={classes.rightText}
            style={{
              background: backgroundTwo,
              width: matches ? "35%" : "25%",
              fontSize: matches ? "0.8em" : "1.4em",
              color: "white"
            }}
            variant="body1"
          >
            {rightLetterText}
          </Typography>
        </div>
        <div className={classes.buttonBlock}>
          <Button
            className={classes.leftButton}
            size="medium"
            variant="contained"
            style={{
              width: matches ? "35%" : "25%",
              fontSize: matches ? "0.8em" : "1.4em"
            }}
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              falsePressed();
            }}
          >
            {leftButtonText}
          </Button>
          <Button
            className={classes.rightButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            style={{
              width: matches ? "35%" : "25%",
              fontSize: matches ? "0.8em" : "1.4em"
            }}
            onClick={e => {
              e.preventDefault();
              truePressed();
            }}
          >
            {rightButtonText}
          </Button>
        </div>
        {!matches && <UseArrowKeys />}
        <Button
          className={classes.beginButton}
          size="medium"
          variant="contained"
          color="primary"
          style={{ width: matches ? "50%" : "20%" }}
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default SecondGame;
