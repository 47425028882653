import React from "react";
import { useSelector } from "react-redux";

import rubadge from "./badges/RU_BADGE.png";
import enbadge from "./badges/EN_BADGE.png";
import debadge from "./badges/DE_BADGE.png";
import ltbadge from "./badges/LT_BADGE.png";
import cnbadge from "./badges/CN_BADGE.png";

const GoogleBadge = () => {
  const id = useSelector((store) => store.language.id);

  const googleBank = [enbadge, rubadge, ltbadge, debadge, cnbadge];

  const googleDescriptionBank = [
    "Get it on Google Play",
    "Доступно в Google Play",
    "Gauti iš Google Play",
    "Jetzt bei Google Play",
    "Get it on Google Play",
  ];

  return (
    <a href="https://play.google.com/store/apps/details?id=com.theuniquebrains.uniquebrains">
      <img
        style={{ maxWidth: "267px", width: "80%", marginLeft: "-17px" }}
        alt={googleDescriptionBank[id]}
        src={googleBank[id]}
      />
    </a>
  );
};
export default GoogleBadge;
