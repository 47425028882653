import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const MyImage = ({ image, onComplete, myOpacity }) => {
  const [margin, setMargin] = React.useState(15);
  const [endPos, setEndPos] = React.useState(-330);

  const matches = useMediaQuery("(max-width:400px)");

  React.useEffect(() => {
    if (matches) {
      setEndPos(-150);
    } else {
      setEndPos(-330);
    }
    function progress() {
      setMargin(oldMargin => {
        if (oldMargin === endPos) {
          clearInterval(timer);
          onComplete();
          return 15;
        }
        return oldMargin - 15;
      });
    }

    const timer = setInterval(progress, 20);
    return () => {
      clearInterval(timer);
    };
  }, [onComplete, matches, endPos]);

  return (
    <img
      style={{
        width: matches ? "45px" : "120px",
        height: matches ? "45px" : "120px",
        marginTop: "15px",
        marginBottom: "auto",
        marginLeft: `${margin}px`,
        marginRight: "auto",
        opacity: myOpacity
      }}
      src={image}
      alt="Logo"
    />
  );
};
MyImage.propTypes = {
  image: PropTypes.string,
  onComplete: PropTypes.func,
  myOpacity: PropTypes.string
};
MyImage.defaultProps = {
  myOpacity: "1"
};
export default MyImage;
