import React from "react";
import { useSelector } from "react-redux";

import rubadge from "./apple_badges/RU_BADGE.svg";
import enbadge from "./apple_badges/EN_BADGE.svg";
import debadge from "./apple_badges/DE_BADGE.svg";
import ltbadge from "./apple_badges/LT_BADGE.svg";
import cnbadge from "./apple_badges/CN_BADGE.svg";

const AppleBadge = () => {
  const id = useSelector((store) => store.language.id);

  const appleBank = [enbadge, rubadge, ltbadge, debadge, cnbadge];

  const appleDescriptionBank = [
    "Get it on Google Play",
    "Доступно в Google Play",
    "Gauti iš Google Play",
    "Jetzt bei Google Play",
    "Get it on Google Play",
  ];

  return (
    <a href="https://apps.apple.com/us/app/unique-brains/id1599069136">
      <img
        style={{ maxWidth: "230px", width: "80%", marginTop: "10px" }}
        alt={appleDescriptionBank[id]}
        src={appleBank[id]}
      />
    </a>
  );
};
export default AppleBadge;
