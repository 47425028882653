import { put, all, takeLatest } from "redux-saga/effects";

import {
  GET_RECORD,
  GET_RECORD_FAILED,
  GET_RECORD_SUCCESS,
  POST_RECORD,
  POST_RECORD_SUCCESS,
  POST_RECORD_FAILED
} from "../actionTypes/record";

import {
  RESPONSE_STATUS,
  getRequest,
  postRequest
} from "../../common/ApiRequests";

function* getRecord(action) {
  const { status, data } = yield getRequest(`record/${action.id}`);
  switch (status) {
    case RESPONSE_STATUS.OK:
      yield put({ type: GET_RECORD_SUCCESS, record: data.record });
      break;
    default:
      yield put({ type: GET_RECORD_FAILED });
  }
}

function* postRecord(action) {
  const obj = { record: action.score, should: true };
  const { status, data } = yield postRequest(`record/${action.id}`, obj);
  switch (status) {
    case RESPONSE_STATUS.OK:
      yield put({ type: POST_RECORD_SUCCESS, record: data.record });
      break;
    default:
      yield put({ type: POST_RECORD_FAILED });
  }
}

function* actionWatcher() {
  yield takeLatest(GET_RECORD, getRecord);
  yield takeLatest(POST_RECORD, postRecord);
}

export function* recordSaga() {
  yield all([actionWatcher()]);
}
