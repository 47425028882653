import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles, Typography, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";

import MyTextField from "../common/MyTextField";
import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import KeyboardLayout from "../common/KeyboardLayout";

const useStyles = makeStyles({
  middleText: {
    fontWeight: "bold",
    width: "80%",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "1.5em",
    height: "40%",
    verticalAlign: "middle"
  },
  buttonBlock: {
    marginBottom: "15px",
    marginTop: "30px"
  },
  submitButton: {
    margin: "5px 0px 5px 0px",
    background: "green",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  beginButton: {
    margin: "10px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

const EighthGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(4);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [userAnswer, setUserAnswer] = React.useState("");
  const [answerText, setAnswerText] = React.useState("1337");
  const classes = useStyles();
  const dispatch = useDispatch();
  var count = 0;
  var myAnswer = 0;
  const matches = useMediaQuery("(max-width:500px)");

  const operationBank = ["+", "-", ":", "*"];
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];
  const submitBank = ["ANSWER", "ОТВЕТИТЬ", "ATSAKYTI", "ANTWORTEN"];
  const answerBank = [
    "Type your answer here",
    "Введите здесь ваш ответ",
    "Įrašykite čia savo atsakymą",
    "Geben Sie hier Ihre Antwort ein"
  ];
  const submitResultBank = [
    "Answer",
    "Отвечайте",
    "Atsakykite",
    "Antworten Sie"
  ];
  const instructionBank = [
    "Count every operation and at the end submit the result",
    "Подсчитайте каждую операцию и в конце отправьте результат",
    "Apskaičiuokite kiekvieną operaciją ir pabaigoje pateikite rezultatą",
    "Zählen Sie jede Operation und geben Sie am Ende das Ergebnis ein"
  ];
  const submitTimeBank = [
    "Submit result of all appeared operations",
    "Отправьте результат всех появившихся операций",
    "Pateikite visų pasirodžiusių operacijų rezultatą",
    "Geben Sie Ergebnis aller angezeigten Operationen ein"
  ];

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "gamedaod290do09dk90a9dsk"
    );
    dispatch(postRecord(8, encryptedAES.toString()));
  };

  const postNewPoints = points => {
    if (points > 0) {
      dispatch(postPoints(points));
    }
  };

  const beginGame = () => {
    setLevel(1);
    setCounter(4);
    setMistakes(0);
    setPoints(0);
    setGameRunning(true);
    generateAnswer();
  };

  const generateAnswer = () => {
    if (level > 30) {
      setCounter(6);
    } else if (level > 15) {
      setCounter(5);
    }
    generateFirstNumber();
  };

  const generateFirstNumber = () => {
    var number = 10 + Math.round(Math.random() * 7 * level);
    setAnswerText(number);
    myAnswer += number;
    count++;
    setTimeout(generateOperation, 1500);
  };

  const generateOperation = () => {
    count++;
    switch (
      operationBank[Math.round(Math.random() * (operationBank.length - 1))]
    ) {
      case "+": {
        generatePlusOperation();
        break;
      }
      case "-": {
        generateMinusOperation();
        break;
      }
      case ":": {
        generateDivideOperation();
        break;
      }
      case "*": {
        generateMultiplyOperation();
        break;
      }
      default: {
      }
    }
  };

  const checkNextOperation = () => {
    if (count === Math.round(level / 13) + 4) {
      beginCountdown();
    } else {
      generateOperation();
    }
  };

  const generateMultiplyOperation = () => {
    var someNumber = Math.round(Math.random() * 9) + 1;
    myAnswer *= someNumber;
    setAnswerText(`* ${someNumber}`);
    setTimeout(checkNextOperation, 1500);
  };

  const generatePlusOperation = () => {
    var someNumber = Math.round(Math.random() * 5 * level) + 1;
    myAnswer += someNumber;
    setAnswerText(`+ ${someNumber}`);
    setTimeout(checkNextOperation, 1500);
  };

  const generateMinusOperation = () => {
    if (myAnswer < 10) {
      generatePlusOperation();
    } else {
      const someNumber = Math.round(Math.random() * (myAnswer - 2)) + 1;
      myAnswer -= someNumber;
      setAnswerText(`- ${someNumber}`);
      setTimeout(checkNextOperation, 1500);
    }
  };

  const generateDivideOperation = () => {
    if (myAnswer < 10) {
      generatePlusOperation();
    } else {
      var valued = true;
      while (valued) {
        const someNumber = Math.round(Math.random() * (myAnswer - 1)) + 1;
        if (myAnswer % someNumber === 0) {
          myAnswer /= someNumber;
          setAnswerText(`: ${someNumber}`);
          setTimeout(checkNextOperation, 1500);
          valued = false;
        }
      }
    }
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const beginCountdown = () => {
    if (matches) {
      setAnswerText(submitResultBank[id]);
    } else {
      setAnswerText(submitTimeBank[id]);
    }
    setAnswer(`${myAnswer}`);
    setIsAnswering(true);
  };

  const submitPressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer === userAnswer) {
          count = 0;
          setUserAnswer("");
          setAnswer(undefined);
          myAnswer = 0;
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const mistakeMade = () => {
    count = 0;
    myAnswer = 0;
    setUserAnswer("");
    setAnswer(undefined);
    if (level > 1) {
      setLevel(1);
      setCounter(4);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (myLevel === 10) {
          if (record < points - 50) {
            postNewRecord(points - 50);
          }
        } else {
          postNewPoints(points - 50);
        }
      } else {
        postNewPoints(points - 50);
      }
    } else {
      setTimeout(generateAnswer, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  const mobileSubmitPressed = superNumber => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer === `${superNumber}`) {
          count = 0;
          setUserAnswer("");
          setAnswer(undefined);
          myAnswer = 0;
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={8}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}
        <div
          style={{
            display: "inline-block",
            width: "70%",
            height: matches ? "120px" : "250px",
            borderStyle: "solid",
            textAlign: "center",
            borderWidth: "1px",
            borderRadius: "5px",
            borderColor: "#000000"
          }}
        >
          <Typography
            className={classes.middleText}
            style={{
              marginTop: matches ? "40px" : "80px"
            }}
            variant="body1"
          >
            {answerText}
          </Typography>
        </div>
        <form>
          {matches && !isAnswering && <KeyboardLayout isDisabled={false} />}
          {matches && isAnswering && (
            <KeyboardLayout
              isDisabled={isAnswering}
              submit={mobileSubmitPressed}
            />
          )}
          {!matches && (
            <MyTextField
              userAnswer={userAnswer}
              onChange={setUserAnswer}
              placeholderText={answerBank[id]}
              isDisabled={!isAnswering}
            />
          )}
          {!matches && (
            <div
              className={classes.buttonBlock}
              style={{ marginTop: matches ? "10px" : "30px" }}
            >
              <Button
                className={classes.submitButton}
                size="medium"
                variant="contained"
                type="submit"
                color="primary"
                style={{ marginTop: matches ? "0px" : "10px" }}
                disabled={!isGameRunning}
                onClick={e => {
                  e.preventDefault();
                  submitPressed();
                }}
              >
                {submitBank[id]}
              </Button>
            </div>
          )}
        </form>
        <Button
          className={classes.beginButton}
          size="medium"
          variant="contained"
          color="primary"
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default EighthGame;
