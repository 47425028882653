export const descriptionData = [
  [
    `Does COLOR of text on the left side match its NAME(TEXT) on the
    right side ?`,
    `Совпадает ли ЦВЕТ левого текста с ИМЕНЕМ(ТЕКСТОМ) с правой стороны?`,
    `Ar sutampa kairio teksto SPALVA su jos PAVADINIMU(TEKSTU) dešinėje pusėje?`,
    `Passt die FARBE vom linken Text zu ihrem NAMEN(TEXT) auf der rechten Seite?`
  ],
  [
    "Select appropriate type of appeared number or letter",
    "Выберите подходящий тип появившейся цифры либо буквы",
    "Pasirinkite tinkamą pasirodančio numerio arba raidės tipą",
    "Wählen Sie den passenden Typ der aufgetauchten Buchstabe oder Nummer"
  ],
  [
    "Click on all colored figures after they have disappeared",
    "Нажмите на все закрашенные фигуры после того как они пропадут",
    "Paspauskite ant visų dažytų figurų po to kai jie pradingo",
    "Klicken Sie auf alle gefärbte Figuren, nachdem sie verschwunden haben"
  ],
  [
    "Are figures in black squares the same ?",
    "Одинаковы ли фигуры в черных квадратах ?",
    "Ar figuros juodose kvadratuose yra vienodi ?",
    "Sind die Figuren in schwarzen Quadraten gleich ?"
  ],
  [
    "Repeat the appeared number",
    "Повторите появившиеся число",
    "Pakartokite pasirodžiusį numerį",
    "Wiederholen Sie die angezeigte Nummer"
  ],
  [
    "Compare appeared equations",
    "Сравните появившиеся уравнения",
    "Palyginkite pasirodžiusias lygtis",
    "Vergleichen Sie angezeigte Gleichungen"
  ],
  [
    "Is appeared maths operation correct ?",
    "Правильна ли появившаяся математическая операция ?",
    "Ar pasirodusi matematikos operacija teisinga ?",
    "Ist die angezeigte Matheoperation wahr ?"
  ],
  [
    "Count every operation and at the end submit the result",
    "Подсчитайте каждую операцию и в конце отправьте результат",
    "Apskaičiuokite kiekvieną operaciją ir pabaigoje pateikite rezultatą",
    "Zählen Sie jede Operation und geben Sie am Ende das Ergebnis ein"
  ],
  [
    "Select numbers in ascending order and then their sum",
    "Выберите числа в порядке возрастания, а затем их сумму",
    "Pasirinkite skaičius didėjančia tvarka ir tada jų sumą",
    "Wählen Sie die Zahlen in aufsteigender Reihenfolge und dann ihre Summe"
  ],
  [
    "Count answer of appeared equation",
    "Подсчитайте ответ появившегося уравнения",
    "Apskaičiuokite lygties atsakymą",
    "Zählen Sie Antwort der erscheinenden Gleichung"
  ],
  [
    "Mark last appeared square",
    "Отметьте последний появившийся квадрат",
    "Pažymėkite paskutinį pasirodžiusį kvadratą",
    "Markieren Sie das zuletzt erschiene Quadrat"
  ],
  [
    "Are color and meaning of word same ?",
    "Одинаковы ли цвет и значение слова ?",
    "Ar žodžio spalva ir reikšmė yra ta pati ?",
    "Sind Farbe und Wortbedeutung gleich ?"
  ],
  [
    "Repeat the sequence of appeared squares",
    "Повторите последовательность появившихся квадратов",
    "Pakartokite pasirodžiusių kvadratų seką",
    "Wiederholen Sie die Reihenfolge der angezeigten Quadrate"
  ],
  [
    "Is current figure same as the last one ?",
    "Соответствует ли текущая фигура предыдущей ?",
    "Ar dabartinė figura sutampa su praeita ?",
    "Stimmt die aktuelle Figur mit der letzten überein ?"
  ],
  [
    "Are COLOR of TEXT and COLOR of shape the same ?",
    "Одинаковы ли ЦВЕТ текста и ЦВЕТ фигуры ?",
    "Ar teksto SPALVA ir figuros SPALVA yra ta pati ?",
    "Sind FARBE vom Text und FARBE der Figur gleich ?"
  ]
];
