import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Container, Card, List } from "@material-ui/core";
import RankingsItem from "./RankingsItem";
import { getRankings } from "../../state/actions/rankings";

const RankingsList = () => {
  const nameData = [
    "Rising Colors",
    "Letter/Number",
    "Just Remember",
    "Sharp eyes",
    "Remember the number",
    "Compare",
    "Correctly?",
    "Mental counting",
    "Number agility",
    "Simple counting",
    "Mark the last",
    "Single Color",
    "Repeat the sequence",
    "Remember previous",
    "Color of shape"
  ];
  const rankings = useSelector(store => store.rankings.items);
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector(store => store.user.user);
  const history = useHistory();

  React.useEffect(() => {
    if (!user) {
      history.push("/sign-in");
    } else {
      dispatch(getRankings(params.id));
    }
    window.scrollTo(0, 0);
  }, [dispatch, params, history, user]);

  return (
    <Container maxWidth="sm">
      <h1 style={{ textAlign: "center", color: "rgba(255, 255, 255, 0.9)" }}>
        {nameData[params.id - 1]}
      </h1>
      <Card
        style={{
          background: "rgba(255, 255, 255, 0.9)",
          borderRadius: "10px",
          marginBottom: "20px"
        }}
      >
        <List>
          {rankings.map(item => {
            return (
              <RankingsItem
                info={item}
                isOnly={rankings.length === 1}
                position={rankings.indexOf(item)}
                key={rankings.indexOf(item)}
                isLastItem={rankings.length - 1 === rankings.indexOf(item)}
              />
            );
          })}
        </List>
      </Card>
    </Container>
  );
};
export default RankingsList;
