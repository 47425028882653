import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles, Typography, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";

import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import UseThreeArrowKeys from "../common/UseThreeArrowKeys";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "20px"
  },
  middleText: {
    fontWeight: "bold",
    width: "80%",
    textAlign: "center",
    marginTop: "80px",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "1.5em",
    height: "40%",
    verticalAlign: "middle"
  },
  leftText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    height: "40px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    marginLeft: "auto",
    marginRight: "20px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  buttonBlock: {
    marginBottom: "15px",
    marginTop: "30px"
  },
  rightText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    height: "40px",
    marginRight: "auto",
    marginLeft: "20px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  block: {
    display: "flex",
    marginBottom: "15px",
    marginTop: "15px",
    width: "100%",
    padding: "5px"
  },
  mainBlock: {
    display: "inline-block",
    width: "50%",
    margin: "10px 40px 10px 40px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  leftButton: {
    width: "20%",
    margin: "5px 0px 5px 0px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  rightButton: {
    width: "20%",
    margin: "5px 0px 5px 20px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  beginButton: {
    width: "20%",
    margin: "20px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

const SixthGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(4);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [equalAnswer, setEqualAnswer] = React.useState(undefined);
  const [moreAnswer, setMoreAnswer] = React.useState(undefined);
  const [lessAnswer, setLessAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [leftText, setLeftText] = React.useState("23 + 19");
  const [rightText, setRightText] = React.useState("34 + 15");
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:500px)");

  const operationBank = ["+", "-", ":", "*"];
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];
  const instructionsBank = [
    "Compare appeared equations",
    "Сравните появившиеся уравнения",
    "Palyginkite pasirodžiusias lygtis",
    "Vergleichen Sie angezeigte Gleichungen"
  ];

  const checkKey = e => {
    if (e.keyCode === 37) {
      falsePressed();
    } else if (e.keyCode === 39) {
      truePressed();
    } else if (e.keyCode === 40) {
      e.preventDefault();
      equalPressed();
    }
  };

  React.useEffect(() => {
    document.onkeydown = checkKey;
    return () => {
      document.onkeydown = undefined;
    };
  });

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "game92djdalsds0a92jdsd9a"
    );
    dispatch(postRecord(6, encryptedAES.toString()));
  };

  const postNewPoints = points => {
    if (points > 0) {
      dispatch(postPoints(points));
    }
  };

  const beginGame = () => {
    setLevel(1);
    setMistakes(0);
    setPoints(0);
    setCounter(4);
    setGameRunning(true);
    generateAnswer();
  };

  const generateAnswer = () => {
    if (level > 30) {
      setCounter(6);
    } else if (level > 15) {
      setCounter(5);
    }
    if (Math.random() * 100 < 30) {
      setEqualAnswer(true);
      generateEqualEquation();
    } else {
      if (Math.random() * 100 < 50) {
        setLessAnswer(true);
        generateLessEquation();
      } else {
        setMoreAnswer(true);
        generateMoreEquation();
      }
    }
  };

  const generateEqualEquation = () => {
    var number = Math.round(Math.random() * level * 12) + 1;
    generateOperation(number, true);
    setTimeout(() => {
      generateOperation(number, false);
    }, 100);
  };

  const generateLessEquation = () => {
    const numberOne = Math.round(Math.random() * level * 12) + 1;
    var numberTwo = 0;
    var valued = true;
    while (valued) {
      numberTwo = Math.round(Math.random() * level * 12) + 1;
      if (numberTwo < numberOne) {
        generateOperation(numberTwo, true);
        setTimeout(() => {
          generateOperation(numberOne, false);
        }, 100);
        valued = false;
      }
    }
  };

  const generateMoreEquation = () => {
    const numberOne = Math.round(Math.random() * level * 12) + 1;
    var numberTwo = 0;
    var valued = true;
    while (valued) {
      numberTwo = Math.round(Math.random() * level * 12) + 1;
      if (numberTwo > numberOne) {
        generateOperation(numberTwo, true);
        setTimeout(() => {
          generateOperation(numberOne, false);
        }, 100);
        valued = false;
      }
    }
  };

  const generateOperation = (number, isLeft) => {
    switch (
      operationBank[Math.round(Math.random() * (operationBank.length - 1))]
    ) {
      case "+": {
        generatePlusOperation(number, isLeft);
        break;
      }
      case "-": {
        generateMinusOperation(number, isLeft);
        break;
      }
      case ":": {
        generateDeleteOperation(number, isLeft);
        break;
      }
      case "*": {
        if (number < 20) {
          generatePlusOperation(number, isLeft);
        } else {
          generateMultiplyOperation(number, isLeft);
        }
        break;
      }
      default: {
      }
    }
  };

  const generatePlusOperation = (number, isLeft) => {
    var someNumber = Math.round(Math.random() * (number - 2));
    const makeRomic =
      level >= 5 &&
      Math.random() * 100 > 50 &&
      someNumber > 0 &&
      someNumber < 39;
    if (makeRomic) {
      generateRomicNumber(someNumber, `${number - someNumber} + `, isLeft);
    } else {
      if (isLeft) {
        setLeftText(`${someNumber} + ${number - someNumber}`);
      } else {
        setRightText(`${someNumber} + ${number - someNumber}`);
        beginCountdown();
      }
    }
  };

  const generateMinusOperation = (number, isLeft) => {
    var someNumber = Math.round(Math.random() * (number - 2)) + 1;
    const makeRomic =
      level >= 5 &&
      Math.random() * 100 > 50 &&
      someNumber > 0 &&
      someNumber < 39;
    if (makeRomic) {
      generateRomicNumber(someNumber, `${number + someNumber} - `, isLeft);
    } else {
      if (isLeft) {
        setLeftText(`${number + someNumber} - ${someNumber}`);
      } else {
        setRightText(`${number + someNumber} - ${someNumber}`);
        beginCountdown();
      }
    }
  };

  const generateDeleteOperation = (number, isLeft) => {
    var someNumber = Math.round(Math.random() * (3 * level)) + 1;
    const makeRomic =
      level >= 5 &&
      Math.random() * 100 > 50 &&
      someNumber > 0 &&
      someNumber < 39;
    if (makeRomic) {
      generateRomicNumber(someNumber, `${number * someNumber} : `, isLeft);
    } else {
      if (isLeft) {
        setLeftText(`${number * someNumber} : ${someNumber}`);
      } else {
        setRightText(`${number * someNumber} : ${someNumber}`);
        beginCountdown();
      }
    }
  };

  const generateRomicNumber = (number, text, isLeft) => {
    var romicString = "";
    var lessNumber = number % 10;
    const tens = (number - lessNumber) / 10;
    var i = 0;
    while (i < tens) {
      romicString += "X";
      i++;
    }
    switch (lessNumber) {
      case 9: {
        romicString += "IX";
        break;
      }
      case 8: {
        romicString += "VIII";
        break;
      }
      case 7: {
        romicString += "VII";
        break;
      }
      case 6: {
        romicString += "VI";
        break;
      }
      case 5: {
        romicString += "V";
        break;
      }
      case 4: {
        romicString += "IV";
        break;
      }
      case 3: {
        romicString += "III";
        break;
      }
      case 2: {
        romicString += "II";
        break;
      }
      case 1: {
        romicString += "I";
        break;
      }
      default: {
        break;
      }
    }
    if (isLeft) {
      setLeftText(text + romicString);
    } else {
      setRightText(text + romicString);
      beginCountdown();
    }
  };

  const generateMultiplyOperation = (number, isLeft) => {
    var someNumber;
    var multiplied = true;
    while (multiplied) {
      someNumber = Math.round(Math.random() * 9) + 1;
      if (number % someNumber === 0) {
        multiplied = false;
        multiplyHelper(number, someNumber, isLeft);
      }
    }
  };

  const multiplyHelper = (number, someNumber, isLeft) => {
    const makeRomic =
      level >= 5 &&
      Math.random() * 100 > 50 &&
      someNumber > 0 &&
      someNumber < 39;
    if (makeRomic) {
      generateRomicNumber(someNumber, `${number / someNumber} * `, isLeft);
    } else {
      if (isLeft) {
        setLeftText(`${number / someNumber} * ${someNumber}`);
      } else {
        setRightText(`${number / someNumber} * ${someNumber}`);
        beginCountdown();
      }
    }
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const beginCountdown = () => {
    setIsAnswering(true);
  };

  const setAllAnswers = () => {
    setEqualAnswer(false);
    setMoreAnswer(false);
    setLessAnswer(false);
  };

  const equalPressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (equalAnswer) {
          setAllAnswers();
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const truePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (lessAnswer) {
          setAllAnswers();
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const falsePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (moreAnswer) {
          setAllAnswers();
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const mistakeMade = () => {
    setAllAnswers();
    if (level > 1) {
      setLevel(1);
      setCounter(4);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        postNewPoints(points - 50);
      }
    } else {
      setTimeout(generateAnswer, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={6}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionsBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}

        <div
          style={{
            display: "inline-block",
            width: matches ? "90%" : "70%",
            height: "100px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "5px",
            borderColor: "#000000"
          }}
        >
          <div
            style={{
              display: "inline-block",
              marginTop: matches ? "40px" : "35px",
              width: "100%",
              height: "60%"
            }}
          >
            <Typography
              style={{
                width: "30%",
                float: "left",
                marginLeft: "5%",
                fontSize: matches ? "0.8rem" : "1rem"
              }}
              variant="body1"
            >
              {leftText}
            </Typography>
            <Typography
              style={{
                width: "30%",
                float: "left",
                fontWeight: "bold",
                fontSize: matches ? "0.8rem" : "1rem"
              }}
              variant="body1"
            >
              ?
            </Typography>
            <Typography
              style={{
                width: "30%",
                float: "left",
                fontSize: matches ? "0.8rem" : "1rem"
              }}
              variant="body1"
            >
              {rightText}
            </Typography>
          </div>
        </div>

        <div className={classes.buttonBlock}>
          <Button
            className={classes.leftButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              falsePressed();
            }}
          >
            {">"}
          </Button>
          <Button
            className={classes.rightButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              equalPressed();
            }}
          >
            {"="}
          </Button>
          <Button
            className={classes.rightButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              truePressed();
            }}
          >
            {"<"}
          </Button>
        </div>
        {!matches && <UseThreeArrowKeys />}
        <Button
          className={classes.beginButton}
          size="medium"
          style={{ width: matches ? "50%" : "20%" }}
          variant="contained"
          color="primary"
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default SixthGame;
