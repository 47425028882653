export const VERIFY_USER = "user/SET_USER";
export const VERIFY_USER_SUCCESS = "user/VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILED = "user/VERIFY_USER_FAILED";
export const SIGN_OUT = "user/SIGN_OUT";
export const VERIFY_EMAIL = "user/VERIFY_EMAIL";
export const VERIFY_EMAIL_FAILED = "user/VERIFY_EMAIL_FAILED";
export const VERIFY_EMAIL_SUCCESS = "user/VERIFY_EMAIL_SUCCESS";
export const SUBMIT_PASSWORD = "user/SUBMIT_PASSWORD";
export const SUBMIT_PASSWORD_SUCCESS = "user/SUBMIT_PASSWORD_SUCCESS";
export const SUBMIT_PASSWORD_FAILED = "user/SUBMIT_PASSWORD_FAILED";
export const CREATE_USER = "user/CREATE_USER";
export const CREATE_USER_SUCCESS = "user/CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "user/CREATE_USER_FAILED";
export const HIDE_SIGNUP_ERROR = "user/HIDE_SIGNUP_ERROR";
export const DISPLAY_USER_ERROR = "user/DISPLAY_USER_ERROR";
export const ADD_USER = "user/ADD_USER";
export const USER = "USER";
export const REMOVE_VERIFY_EMAIL_MESSAGE = "user/REMOVE_SUBMIT_EMAIL_MESSAGE";
export const REMOVE_SUBMIT_PASSWORD_MESSAGE =
  "user/REMOVE_SUBMIT_PASSWORD_MESSAGE";
export const HIDE_SIGNIN_ERROR = "user/HIDE_SIGNIN_ERROR";
