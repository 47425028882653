import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";

import { makeStyles, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";

import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import MyColoredButton from "../common/MyColoredButton";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "20px"
  },
  buttonBlock: {
    display: "inline-block",
    width: "100%"
  },
  rightText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    height: "40px",
    marginRight: "auto",
    marginLeft: "20px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  block: {
    display: "flex",
    marginBottom: "15px",
    marginTop: "15px",
    width: "100%",
    padding: "5px"
  },
  mainBlock: {
    display: "inline-block",
    width: "50%",
    margin: "10px 40px 10px 40px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  coloredButton: {
    width: "60px",
    height: "60px",
    background: "grey",
    "&:hover": {
      background: "black"
    }
  },
  beginButton: {
    margin: "20px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

const EleventhGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(4);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [isHighlighted, setHighlighted] = React.useState([]);
  const [tempHighlighted, setTempHighlighted] = React.useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];
  const instructionsBank = [
    "Mark last appeared square",
    "Отметьте последний появившийся квадрат",
    "Pažymėkite paskutinį pasirodžiusį kvadratą",
    "Markieren Sie das zuletzt erschiene Quadrat"
  ];

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "gameap2092dsad8jsdksals8"
    );
    dispatch(postRecord(11, encryptedAES.toString()));
  };

  const postNewPoints = points => {
    if (points > 0) {
      dispatch(postPoints(points));
    }
  };

  const beginGame = () => {
    setLevel(1);
    setCounter(4);
    setMistakes(0);
    setPoints(0);
    setGameRunning(true);
    setTempHighlighted([]);
    generateView();
  };

  const generateView = () => {
    if (level > 30) {
      setCounter(10);
    } else if (level > 20) {
      setCounter(7);
    } else if (level > 10) {
      setCounter(5);
    }
    var valued = true;
    while (valued) {
      const num = Math.round(Math.random() * 71);
      if (!tempHighlighted.includes(num)) {
        valued = false;
        const arr = tempHighlighted;
        setAnswer(num);
        arr.push(num);
        setTempHighlighted(arr);
        setHighlighted(arr);
        setTimeout(emptyHighlighted, 2000);
      }
    }
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const emptyHighlighted = () => {
    setHighlighted([]);
    beginCountdown();
  };

  const beginCountdown = () => {
    setIsAnswering(true);
  };

  const checkAnswer = id => {
    if (isAnswering) {
      setIsAnswering(false);
      if (id === answer) {
        setPoints(points + 50 + level * 10);
        setLevel(level + 1);
        setTimeout(generateView, 100);
      } else {
        mistakeMade();
      }
    }
  };

  const mistakeMade = () => {
    if (level > 1) {
      setCounter(4);
      setLevel(level - 1);
      const arr = tempHighlighted;
      const index = arr.indexOf(answer);
      arr.splice(index, index + 1);
      setTempHighlighted(arr);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        postNewPoints(points - 50);
      }
    } else {
      setTimeout(generateView, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={11}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionsBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}

        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={0}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={1}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={2}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={3}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={4}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={5}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={6}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={7}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={8}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={9}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={10}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={11}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={12}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={13}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={14}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={15}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={16}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={17}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={18}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={19}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={20}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={21}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={22}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={23}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={24}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={25}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={26}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={27}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={28}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={29}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={30}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={31}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={32}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={33}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={34}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={35}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={36}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={37}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={38}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={39}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={40}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={41}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={42}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={43}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={44}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={45}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={46}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={47}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={48}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={49}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={50}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={51}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={52}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={53}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={54}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={55}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={56}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={57}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={58}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={59}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={60}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={61}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={62}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={63}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={64}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={65}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={66}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={67}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={68}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={69}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={70}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={71}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>

        <Button
          className={classes.beginButton}
          size="medium"
          variant="contained"
          color="primary"
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default EleventhGame;
