import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const RankingsItem = ({ info, isOnly, position, isLastItem }) => {
  const id = useSelector(store => store.language.id);
  const user = useSelector(store => store.user.user);

  const postBank = ["Pos.", "Поз.", "Poz.", "Pos."];
  const nameBank = ["Nickname", "Никнейм", "Nickname", "Nickname"];
  const scoreBank = ["Points", "Очки", "Taškai", "Punkte"];

  return (
    <div
      style={{
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        borderRightColor: "#000000",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderLeftColor: "#000000",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderTopColor: "#000000",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderBottomColor: isOnly ? "#000000" : !isLastItem ? "" : "#000000",
        borderBottomStyle: isOnly ? "solid" : !isLastItem ? "" : "solid",
        borderBottomWidth: isOnly ? "1px" : !isLastItem ? "" : "1px",
        backgroundColor:
          user && user.sub === info.name
            ? "rgba(255, 0, 0, 0.3)"
            : position === 1
            ? "#d4af3790"
            : position === 2
            ? "rgba(192,192,192, 0.7)"
            : position === 3
            ? "rgb(25, 59, 86, 0.2)"
            : ""
      }}
    >
      <div
        style={{
          width: "10%",
          display: "inline-block",
          textAlign: "center",
          borderRightColor: "#000000",
          borderRightStyle: "solid",
          borderRightWidth: "1px"
        }}
      >
        {position !== 0 ? position : postBank[id]}
      </div>
      <div
        style={{
          width: "50%",
          paddingLeft: "5px",
          display: "inline-block",
          textAlign: "center",
          borderRightColor: "#000000",
          borderRightStyle: "solid",
          borderRightWidth: "1px"
        }}
      >
        {position !== 0 ? info.name : nameBank[id]}
      </div>
      <div
        style={{
          width: "40%",
          display: "inline-block",
          float: "right",
          textAlign: "center"
        }}
      >
        {position !== 0 ? info.score : scoreBank[id]}
      </div>
    </div>
  );
};
RankingsItem.propTypes = {
  info: PropTypes.object,
  position: PropTypes.number,
  isLastItem: PropTypes.bool,
  isOnly: PropTypes.bool
};
export default RankingsItem;
