import React from "react";
import { useParams, useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";

import FormError from "../common/FormError";
import {
  submitPassword,
  hideRestorePasswordError
} from "../../state/actions/user";
import {
  passwordValidation,
  passwordsMatchValidation,
  whitespaceValidation,
  passwordLengthValidation,
  passwordContainValidation
} from "../../common/TextFieldValidation";

const passwordMatchBank = [
  "Passwords must match",
  "Пароли должны совпадать",
  "Slaptažodžiai turi sutapti",
  "Passwörter müssen übereinstimmen"
];
const passwordContainBank = [
  "Password must contain at least 1 letter and 1 number",
  "Пароль должен содержать не менее 1 буквы и 1 цифры",
  "Slaptažodyje turi būti bent 1 raidė ir 1 numeris",
  "Passwort muss mindestens 1 Buchstabe und 1 Ziffer enthalten"
];
const passwordLengthBank = [
  "Must be at least 8 characters long",
  "Должно быть не менее 8 символов",
  "Turi būti ne mažiau kaip 8 simboliai",
  "Muss mindestens 8 Zeichen lang sein"
];

const whitespacesBank = [
  "Whitespaces are not allowed",
  "Пробелы не допускаются",
  "Tarpai neleidžiami",
  "Leerzeichen sind nicht erlaubt"
];
const minimumBank = [
  "Minimum 8 characters long",
  "Минимум 8 символов",
  "Mažiausiai 8 simboliai",
  "Mindestens 8 Zeichen lang"
];
const changedBank = [
  "Password was changed",
  "Пароль был изменен",
  "Slaptažodis buvo pakeistas",
  "Passwort wurde geändert"
];
const restorePasswordBank = [
  "Restore password",
  "Восстановить пароль",
  "Atkurti slaptažodį",
  "Passwort wiederherstellen"
];

const passwordBank = ["Password *", "Пароль *", "Slaptažodis *", "Passwort *"];
const repeatPasswordBank = [
  "Repeat password *",
  "Повторите пароль *",
  "Pakartokite slaptažodį *",
  "Passwort wiederholen *"
];
const correctBank = [
  "Please correct any invalid fields and try again",
  "Пожалуйста исправьте все недействительные поля и попробуйте снова",
  "Ištaisykite netinkamus laukus ir bandykite dar kartą",
  "Bitte korrigieren Sie alle ungültigen Felder und versuchen Sie es erneut"
];
const mainError = [
  "Couldn't connect to server",
  "Не удалось подключиться",
  "Nepavyko prisijungti",
  "Verbindung fehlgeschalgen"
];

const RestorePasswordForm = () => {
  const [localError, setLocalError] = React.useState(false);
  const passwordError = useSelector(store => store.user.passwordSubmitError);
  const passwordSuccess = useSelector(
    store => store.user.passwordSubmitSuccess
  );
  const id = useSelector(store => store.language.id);
  const isSubmiting = useSelector(store => store.user.isSubmiting);
  let { userCode } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const verifyPassword = (firstPassword, secondPassword) => {
    if (
      passwordValidation(firstPassword) &&
      passwordsMatchValidation(firstPassword, secondPassword)
    ) {
      setLocalError(false);
      submit(firstPassword);
    } else {
      setLocalError(true);
    }
  };

  const redirect = () => {
    setTimeout(() => {
      history.push("/sign-in");
    }, 2000);
  };

  const submit = password => {
    dispatch(submitPassword(password, userCode, redirect, id));
    setTimeout(() => {
      dispatch(hideRestorePasswordError());
    }, 10000);
  };

  const firstPasswordError = firstPassword => {
    if (!passwordContainValidation(firstPassword)) {
      return <FormError message={passwordContainBank[id]} />;
    }
    if (!whitespaceValidation(firstPassword)) {
      return <FormError message={whitespacesBank[id]} />;
    }
    if (!passwordLengthValidation(firstPassword)) {
      return <FormError message={passwordLengthBank[id]} />;
    }
  };

  const secondPasswordError = (firstPassword, secondPassword) => {
    if (!passwordsMatchValidation(firstPassword, secondPassword)) {
      return <FormError message={passwordMatchBank[id]} />;
    }
  };

  return (
    <Container maxWidth="sm">
      <Card
        style={{
          borderRadius: "20px",
          padding: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          background: "rgba(255, 255, 255, 0.9)"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "20px"
          }}
        >
          {restorePasswordBank[id]}
        </h1>
        <Formik
          initialValues={{ passwordOne: "", passwordTwo: "" }}
          onSubmit={values => {
            verifyPassword(values.passwordOne, values.passwordTwo);
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                required
                name="passwordOne"
                variant="outlined"
                placeholder={passwordBank[id]}
                helperText={minimumBank[id]}
                type="password"
                value={values.passwordOne}
                onChange={handleChange}
              />
              {localError && firstPasswordError(values.passwordOne)}
              <TextField
                fullWidth
                required
                name="passwordTwo"
                variant="outlined"
                placeholder={repeatPasswordBank[id]}
                type="password"
                style={{ marginTop: "20px" }}
                value={values.passwordTwo}
                onChange={handleChange}
              />
              {localError &&
                secondPasswordError(values.passwordOne, values.passwordTwo)}
              <Button
                type="submit"
                disabled={isSubmiting}
                style={{
                  marginTop: "20px",
                  background: "rgba(29,115,173, 0.8)",
                  width: "60%",
                  marginLeft: "20%",
                  marginRight: "20%",
                  borderRadius: "20px",
                  textTransform: "none",
                  fontSize: "1.4em"
                }}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
              {localError && <FormError Tag={"h2"} message={correctBank[id]} />}
              {passwordError && (
                <FormError Tag={"h2"} message={mainError[id]} />
              )}
              {passwordSuccess && (
                <FormError
                  Tag={"h2"}
                  color={"green"}
                  message={changedBank[id]}
                />
              )}
            </form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};
export default RestorePasswordForm;
