import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import GridItem from "./GridItem";
import maincolors from "../common/main/maincolors.png";
import maincolorsandshapes from "../common/main/maincolorsandshapes.png";
import maincompare from "../common/main/maincompare.jpg";
import mainmaths from "../common/main/mainmaths.jpg";
import mainphotofigures from "../common/main/mainphotofigures.png";
import memorymain from "../common/main/memorymain.jpg";
import letternumbermain from "../common/main/letternumbermain.jpg";
import { descriptionData } from "../common/descriptionData";

const AllList = () => {
  const id = useSelector(store => store.language.id);
  const user = useSelector(store => store.user.user);
  const level = useSelector(store => store.points.level);
  const history = useHistory();

  React.useEffect(() => {
    if (!user) {
      history.push("/sign-in");
    }
  }, [user, history]);

  const linkData = [
    "/games/risingcolors",
    "/games/letternumber",
    "/games/justremember",
    "/games/sharpeyes",
    "/games/remembernumber",
    "/games/compare",
    "/games/correctly",
    "/games/mentalcounting",
    "/games/numberagility",
    "/games/simplecounting",
    "/games/markthelast",
    "/games/singlecolor",
    "/games/repeatthesequence",
    "/games/likeprevious",
    "/games/shapecolor"
  ];
  const nameData = [
    "Rising Colors",
    "Letter/Number",
    "Just Remember",
    "Sharp eyes",
    "Remember the number",
    "Compare",
    "Correctly?",
    "Mental counting",
    "Number agility",
    "Simple counting",
    "Mark the last",
    "Single Color",
    "Repeat the sequence",
    "Remember previous",
    "Color of shape"
  ];
  const imageData = [
    maincolors,
    letternumbermain,
    memorymain,
    mainphotofigures,
    memorymain,
    maincompare,
    maincompare,
    mainmaths,
    mainmaths,
    mainmaths,
    memorymain,
    maincolors,
    memorymain,
    memorymain,
    maincolorsandshapes
  ];

  const hardnessData = [
    "HARD",
    "MID",
    "MID",
    "MID",
    "EASY",
    "HARD",
    "HARD",
    "MID",
    "HARD",
    "EASY",
    "HARD",
    "EASY",
    "HARD",
    "EASY",
    "EASY"
  ];

  const titleBank = ["All Games", "Все игры", "Visi žaidimai", "Alle Spiele"];

  return (
    <Container maxWidth="md">
      <h1 style={{ textAlign: "center", color: "rgba(255, 255, 255, 0.9)" }}>
        {titleBank[id]}
      </h1>
      <Grid container spacing={1}>
        {linkData
          .filter(item => {
            const hardness = hardnessData[linkData.indexOf(item)];
            if (hardness === "EASY") {
              return true;
            } else if (hardness === "MID" && level > 5) {
              return true;
            } else if (level >= 10) {
              return true;
            }
            return false;
          })
          .map(item => {
            return (
              <GridItem
                name={nameData[linkData.indexOf(item)]}
                hardness={hardnessData[linkData.indexOf(item)]}
                description={descriptionData[linkData.indexOf(item)][id]}
                image={imageData[linkData.indexOf(item)]}
                link={item}
                key={linkData.indexOf(item)}
              />
            );
          })}
      </Grid>
    </Container>
  );
};
export default AllList;
