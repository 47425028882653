import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";

import { makeStyles, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";

import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import MyColoredButton from "../common/MyColoredButton";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "20px"
  },
  middleText: {
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    fontSize: "1.4em"
  },
  leftText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    height: "40px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    marginLeft: "auto",
    marginRight: "20px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  answerButtonBlock: {
    marginTop: "15px"
  },
  rightText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    height: "40px",
    marginRight: "auto",
    marginLeft: "20px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  block: {
    display: "flex",
    marginBottom: "15px",
    marginTop: "15px",
    width: "100%",
    padding: "5px"
  },
  mainBlock: {
    display: "inline-block",
    width: "50%",
    margin: "10px 40px 10px 40px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  leftButton: {
    width: "25%",
    margin: "5px 20px 5px 0px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  rightButton: {
    width: "25%",
    margin: "5px 0px 5px 20px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  beginButton: {
    margin: "20px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

const NinethGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [leftUpButtonText, setLeftUpButtonText] = React.useState("22");
  const [rightUpButtonText, setRightUpButtonText] = React.useState("23");
  const [rightDownButtonText, setRightDownButtonText] = React.useState("37");
  const [leftDownButtonText, setLeftDownButtonText] = React.useState("38");
  const [isHighlighted, setHighlighted] = React.useState([]);
  const [counter, setCounter] = React.useState(4);
  const [areNumbersSubmitted, setAreNumbersSubmitted] = React.useState(false);
  const classes = useStyles();
  const [values, setValues] = React.useState([]);
  const [valuesId, setValuesId] = React.useState([]);
  const [myTempValuesId, setMyTempValuesId] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [myFalseAnswers, setMyFalseAnswers] = React.useState([]);
  const dispatch = useDispatch();
  var tempValues = [];
  var tempValuesId = [];
  var myAnswer = 0;
  var count = 0;
  var tempHighlightedArray = [];
  var falseAnswers = [];

  const idBank = [
    0,
    1,
    2,
    3,
    4,
    5,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35
  ];
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];
  const instructionsBank = [
    "Select numbers in ascending order and then their sum",
    "Выберите числа в порядке возрастания, а затем их сумму",
    "Pasirinkite skaičius didėjančia tvarka ir tada jų sumą",
    "Wählen Sie die Zahlen in aufsteigender Reihenfolge und dann ihre Summe"
  ];

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "gamedopaod0928d98wujdskd"
    );
    dispatch(postRecord(9, encryptedAES.toString()));
  };

  const postNewPoints = points => {
    if (points > 0) {
      dispatch(postPoints(points));
    }
  };

  const beginGame = () => {
    setLevel(1);
    setCounter(4);
    setMistakes(0);
    setPoints(0);
    setGameRunning(true);
    generateAnswer();
  };

  const generateAnswer = () => {
    if (level > 30) {
      setCounter(6);
    } else if (level > 15) {
      setCounter(5);
    }
    if (count !== Math.round(level / 13) + 4) {
      generateNumber();
    } else {
      createButtonValues();
    }
  };

  const generateNumber = () => {
    count++;
    var valued = true;
    while (valued) {
      const someNumber = Math.round(Math.random() * 10 * level) + 1;
      if (!tempValues.includes(someNumber)) {
        valued = false;
        tempValues.push(someNumber);
        generateId();
      }
    }
  };

  const generateId = () => {
    var valued = true;
    while (valued) {
      const idNumber = idBank[Math.round(Math.random() * (idBank.length - 1))];
      if (!tempValuesId.includes(idNumber)) {
        valued = false;
        tempValuesId.push(idNumber);
        generateAnswer();
      }
    }
  };

  const createButtonValues = () => {
    var sum = 0;
    for (var i = 0; i < tempValues.length; i++) {
      sum += tempValues[i];
      if (i === tempValues.length - 1) {
        myAnswer = sum;
        sortAndGenerateFalseAnswers();
      }
    }
  };

  const sortAndGenerateFalseAnswers = () => {
    tempValues.sort((a, b) => {
      return a - b;
    });
    count = 0;
    falseAnswers.push(myAnswer);
    while (count < 3) {
      if (Math.random() * 10 > 5) {
        const someNumber = Math.round(Math.random() * (myAnswer - 2)) + 1;
        if (!falseAnswers.includes(someNumber)) {
          count++;
          falseAnswers.push(someNumber);
        }
      } else {
        const someNumber = myAnswer + Math.round(Math.random() * myAnswer) + 1;
        if (!falseAnswers.includes(someNumber)) {
          count++;
          falseAnswers.push(someNumber);
        }
      }
      if (count === 3) {
        falseAnswers.sort((a, b) => {
          return a - b;
        });
        setMyFalseAnswers(falseAnswers);
        begin();
      }
    }
  };

  const begin = () => {
    setAnswer(`${myAnswer}`);
    setValues(tempValues);
    setValuesId(tempValuesId);
    setMyTempValuesId(tempValuesId);
    beginCountdown();
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const beginCountdown = () => {
    setIsAnswering(true);
  };

  const leftUpButtonPressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      if (`${answer}` === `${leftUpButtonText}`) {
        setLevel(level + 1);
        setPoints(points + 100);
        nullEverything();
      } else {
        mistakeMade();
      }
    }
  };

  const rightUpButtonPressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      if (`${answer}` === `${rightUpButtonText}`) {
        setLevel(level + 1);
        setPoints(points + 100);
        nullEverything();
      } else {
        mistakeMade();
      }
    }
  };

  const leftDownButtonPressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      if (`${answer}` === `${leftDownButtonText}`) {
        setLevel(level + 1);
        setPoints(points + 100);
        nullEverything();
      } else {
        mistakeMade();
      }
    }
  };

  const rightDownButtonPressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      if (`${answer}` === `${rightDownButtonText}`) {
        setLevel(level + 1);
        setPoints(points + 100);
        nullEverything();
      } else {
        mistakeMade();
      }
    }
  };

  const nullEverything = () => {
    setValues([]);
    setMyTempValuesId([]);
    setValuesId([]);
    setHighlighted([]);
    setCurrentIndex(0);
    setAreNumbersSubmitted(false);
    setTimeout(generateAnswer, 100);
  };

  const mistakeMade = () => {
    setIsAnswering(false);
    if (level > 1) {
      setLevel(1);
      setCounter(4);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      setValues([]);
      setMyTempValuesId([]);
      setValuesId([]);
      setHighlighted([]);
      setCurrentIndex(0);
      setAreNumbersSubmitted(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        postNewPoints(points - 50);
      }
    } else {
      setTimeout(nullEverything, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  const checkAnswer = id => {
    if (myTempValuesId.indexOf(id) === currentIndex) {
      setCurrentIndex(currentIndex + 1);
      tempHighlightedArray.push(id);
      setHighlighted(oldHighlighted => {
        const arr = oldHighlighted;
        arr.push(id);
        return arr;
      });
      setPoints(points + 10);
      if (myTempValuesId.length - 1 === myTempValuesId.indexOf(id)) {
        fillButtons();
      }
    } else {
      mistakeMade();
    }
  };

  const fillButtons = () => {
    setLeftUpButtonText(myFalseAnswers[0]);
    setRightUpButtonText(myFalseAnswers[1]);
    setLeftDownButtonText(myFalseAnswers[2]);
    setRightDownButtonText(myFalseAnswers[3]);
    setAreNumbersSubmitted(true);
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={9}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionsBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={0}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={1}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={2}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={3}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={4}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={5}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={6}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={7}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={8}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={9}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={10}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={11}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={12}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={13}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={14}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={15}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={16}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={17}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={18}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={19}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={20}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={21}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={22}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={23}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={24}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={25}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={26}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={27}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={28}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={29}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.buttonBlock}>
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={30}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={31}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={32}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={33}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={34}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
          <MyColoredButton
            isGameRunning={isGameRunning}
            id={35}
            values={values}
            valuesId={valuesId}
            isHighlighted={isHighlighted}
            checkAnswer={checkAnswer}
          />
        </div>
        <div className={classes.answerButtonBlock}>
          <Button
            className={classes.leftButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!areNumbersSubmitted}
            onClick={e => {
              e.preventDefault();
              leftUpButtonPressed();
            }}
          >
            {leftUpButtonText}
          </Button>
          <Button
            className={classes.rightButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!areNumbersSubmitted}
            onClick={e => {
              e.preventDefault();
              rightUpButtonPressed();
            }}
          >
            {rightUpButtonText}
          </Button>
        </div>

        <div className={classes.answerButtonBlock}>
          <Button
            className={classes.leftButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!areNumbersSubmitted}
            onClick={e => {
              e.preventDefault();
              leftDownButtonPressed();
            }}
          >
            {leftDownButtonText}
          </Button>
          <Button
            className={classes.rightButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!areNumbersSubmitted}
            onClick={e => {
              e.preventDefault();
              rightDownButtonPressed();
            }}
          >
            {rightDownButtonText}
          </Button>
        </div>
        <Button
          className={classes.beginButton}
          size="medium"
          variant="contained"
          color="primary"
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default NinethGame;
