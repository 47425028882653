import {
  POST_RECORD,
  POST_RECORD_SUCCESS,
  POST_RECORD_FAILED,
  GET_RECORD,
  GET_RECORD_SUCCESS,
  GET_RECORD_FAILED,
  SET_ZERO_RECORD
} from "../actionTypes/record";

const initialState = {
  score: 0
};

export default function recordReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ZERO_RECORD: {
      return { ...state, score: 0 };
    }
    case GET_RECORD: {
      return {
        ...state
      };
    }
    case GET_RECORD_SUCCESS: {
      return { ...state, score: action.record };
    }
    case GET_RECORD_FAILED: {
      return {
        ...state,
        record: 0
      };
    }
    case POST_RECORD: {
      return {
        ...state
      };
    }
    case POST_RECORD_SUCCESS: {
      return { ...state, score: action.record };
    }
    case POST_RECORD_FAILED: {
      return {
        ...state
      };
    }
    default: {
      return state;
    }
  }
}
