import { Link } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { signOut } from "../../state/actions/user";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  leftButton: {
    backgroundColor: "transparent",
    textTransform: "none",
    marginBottom: "2px",
    fontWeight: "bold",
    borderLeftColor: "rgba(255, 255, 255, 0.5)",
    borderLeftStyle: "solid",
    borderLeftWidth: "1px",
    borderTopColor: "rgba(255, 255, 255, 0.5)",
    borderTopStyle: "solid",
    borderTopWidth: "1px",
    marginLeft: "2px",
    marginRight: "2px",
    marginTop: "2px",
    borderRadius: "20px 0px 0px 0px",
    color: "#1D73AD",
    "&:hover": {
      color: "#FFFFFF"
    },
    "&:focus": {
      color: "#FFFFFF"
    }
  },
  rightButton: {
    backgroundColor: "transparent",
    textTransform: "none",
    fontWeight: "bold",
    borderRightColor: "rgba(255, 255, 255, 0.5)",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    marginLeft: "2px",
    marginTop: "2px",
    marginRight: "2px",
    marginBottom: "2px",
    borderBottomColor: "rgba(255, 255, 255, 0.5)",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderRadius: "0px 0px 20px 0px",
    color: "#1D73AD",
    "&:hover": {
      color: "#FFFFFF"
    },
    "&:focus": {
      color: "#FFFFFF"
    }
  },
  rankingsButton: {
    backgroundColor: "transparent",
    textTransform: "none",
    fontSize: "1.4em",
    fontWeight: "bold",
    marginLeft: "10px",
    marginTop: "2px",
    marginBottom: "2px",
    marginRight: "25px",
    borderColor: "rgba(255, 255, 255, 0.5)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "0px 0px 0px 20px",
    color: "#1D73AD",
    "&:hover": {
      color: "#FFFFFF"
    },
    "&:focus": {
      color: "#FFFFFF"
    }
  },
  signOutButton: {
    backgroundColor: "transparent",
    textTransform: "none",
    fontSize: "1.4em",
    fontWeight: "bold",
    marginLeft: "2px",
    marginRight: "2px",
    marginTop: "2px",

    marginBottom: "2px",
    borderColor: "rgba(255, 255, 255, 0.5)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "0px 0px 20px 0px",
    color: "#1D73AD",
    "&:hover": {
      color: "#FFFFFF"
    },
    "&:focus": {
      color: "#FFFFFF"
    }
  },
  functionsButton: {
    backgroundColor: "transparent",
    textTransform: "none",
    fontSize: "1.4em",
    fontWeight: "bold",
    marginLeft: "2px",
    marginRight: "2px",
    marginTop: "2px",
    color: "#1D73AD",
    "&:hover": {
      color: "#FFFFFF"
    },
    "&:focus": {
      color: "#FFFFFF"
    }
  },
  functionsBar: {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    background: "rgba(171, 190, 231, 0.3)",
    boxShadow: "0 3px 3px -2px gray",
    borderRadius: "0px 0px 60px 60px"
  }
});

const FunctionsNavBar = () => {
  const id = useSelector(store => store.language.id);
  const user = useSelector(store => store.user.user);
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:500px)");

  const allGamesBank = [
    "All Games",
    "Все игры",
    "Visi žaidimai",
    "Alle Spiele"
  ];
  const mathsBank = ["Maths", "Математические", "Matematiniai", "Mathe"];
  const memoryBank = ["Memory", "Память", "Atminties", "Gedächtnis"];
  const agilityBank = [
    "Agility",
    "Пластичность",
    "Judrumo",
    "Geschicklichkeit"
  ];
  const signIn = ["Sign in", "Войти", "Prisijungti", "Einloggen"];
  const signUp = [
    "Sign up",
    "Зарегистрироваться",
    "Užsiregistruoti",
    "Anmelden"
  ];
  const or = ["or", "или", "arba", "oder"];
  const rankingsBank = ["Rankings", "Рейтинг", "Reitingas", "Rang"];

  const signOutBank = ["Sign out", "Выйти", "Išeiti", "Ausloggen"];
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(signOut());
  };

  React.useEffect(() => {
    if (!user) {
      logout();
    }
  });

  return (
    <Container maxWidth="md">
      {!user && (
        <div
          className={classes.functionsBar}
          style={{
            padding: matches ? "5px 12px 12px 12px" : "15px 30px 15px 30px"
          }}
        >
          <Link to="/sign-in">
            <Button
              className={classes.leftButton}
              style={{ fontSize: matches ? "1em" : "1.4em" }}
            >
              {signIn[id]}
            </Button>
          </Link>
          <span
            style={{
              color: "rgba(255, 255, 255, 0.9)",
              marginLeft: "2px",
              marginRight: "2px",
              fontSize: matches ? "0.8em" : "1em"
            }}
          >
            {or[id]}
          </span>
          <Link to="/sign-up">
            <Button
              className={classes.rightButton}
              style={{ fontSize: matches ? "1em" : "1.4em" }}
            >
              {signUp[id]}
            </Button>
          </Link>
        </div>
      )}
      {user && (
        <div
          className={classes.functionsBar}
          style={{
            padding: matches ? "5px 10px 10px 10px" : "15px 30px 15px 30px"
          }}
        >
          <Link to="/games/all">
            <Button
              className={classes.leftButton}
              style={{
                fontSize: matches ? "1em" : "1.4em",
                width: matches ? "40%" : ""
              }}
            >
              {allGamesBank[id]}
            </Button>
          </Link>
          <Link to="/games/maths">
            <Button
              className={classes.functionsButton}
              style={{
                fontSize: matches ? "1em" : "1.4em",
                width: matches ? "40%" : ""
              }}
            >
              {mathsBank[id]}
            </Button>
          </Link>
          <Link to="/games/memory">
            <Button
              className={classes.functionsButton}
              style={{
                fontSize: matches ? "1em" : "1.4em",
                width: matches ? "40%" : ""
              }}
            >
              {memoryBank[id]}
            </Button>
          </Link>
          <Link to="/games/agility">
            <Button
              className={classes.rightButton}
              style={{
                fontSize: matches ? "1em" : "1.4em",
                width: matches ? "40%" : ""
              }}
            >
              {agilityBank[id]}
            </Button>
          </Link>
          <Link to="/rankings">
            <Button
              className={classes.rankingsButton}
              style={{
                fontSize: matches ? "1em" : "1.4em",
                width: matches ? "40%" : "",
                marginLeft: matches ? "2px" : "10px",
                marginRight: matches ? "2px" : "25px",
                marginBottom: matches ? "5px" : "2px"
              }}
            >
              {rankingsBank[id]}
            </Button>
          </Link>
          <Button
            className={classes.signOutButton}
            onClick={logout}
            style={{
              fontSize: matches ? "1em" : "1.4em",
              width: matches ? "40%" : "",
              marginBottom: matches ? "5px" : "2px"
            }}
          >
            {signOutBank[id]}
          </Button>
        </div>
      )}
    </Container>
  );
};
export default FunctionsNavBar;
