import React from "react";
import { useSelector } from "react-redux";

const UseArrowKeys = () => {
  const id = useSelector(store => store.language.id);
  const arrowsBank = [
    "You can use arrows: < for (NO) and > for (YES) to answer faster",
    "Вы можете использовать стрелки: < для (НЕТ) и > для (ДА) что бы отвечать быстрее",
    "Jūs galite naudoti rodykles: < kaip (NE) ir > kaip (TAIP), kad atsakyti greičiau",
    "Sie können Pfeile benutzen: < für (NEIN) und > für (JA), um schneller zu antworten"
  ];
  return (
    <h2
      style={{
        width: "80%",
        padding: "5px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "5px",
        borderColor: "#808080",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        fontStyle: "italic"
      }}
    >
      {arrowsBank[id]}
    </h2>
  );
};
export default UseArrowKeys;
