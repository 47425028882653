import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";

import { makeStyles, Typography, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import quadratyellow from "../common/quadratyellow.jpg";
import theroundblack from "../common/theroundblack.png";
import trianglepurple from "../common/trianglepurple.png";
import quadratblue from "../common/quadratblue.jpg";
import theroundgreen from "../common/theroundgreen.png";
import trianglered from "../common/trianglered.png";
import UseArrowKeys from "../common/UseArrowKeys";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "20px"
  },
  middleText: {
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    paddingTop: "5px",
    fontSize: "1.4em"
  },
  buttonBlock: {
    marginBottom: "15px",
    marginTop: "20px"
  },
  block: {
    display: "flex",
    marginBottom: "15px",
    marginTop: "15px",
    width: "100%",
    padding: "5px"
  },
  mainBlock: {
    display: "inline-block",
    width: "50%",
    margin: "10px 40px 10px 40px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  leftButton: {
    width: "25%",
    margin: "5px 20px 5px 0px",
    fontSize: "1.4em",
    background: "red",
    "&:hover": {
      background: "black"
    }
  },
  rightButton: {
    width: "25%",
    margin: "5px 0px 5px 20px",
    fontSize: "1.4em",
    background: "green",
    "&:hover": {
      background: "black"
    }
  },
  beginButton: {
    width: "20%",
    margin: "20px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

const FifteenthGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(6);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [currentView, setCurrentView] = React.useState(theroundblack);
  const [currentColor, setCurrentColor] = React.useState("black");
  const [currentName, setCurrentName] = React.useState("black");
  const [isShowing, setIsShowing] = React.useState(true);
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:650px)");

  var tempView = theroundblack;
  var tempColor = "black";
  var tempName = "black";
  const classes = useStyles();

  const imagesBank = [
    theroundblack,
    theroundgreen,
    trianglepurple,
    trianglered,
    quadratblue,
    quadratyellow
  ];

  const mySuperBank = [
    ["red", "yellow", "green", "purple", "black"],
    ["красный", "желтый", "зеленый", "фиолетовый", "черный"],
    ["raudonas", "geltonas", "žalias", "purpurinis", "juodas"],
    ["rot", "gelb", "grün", "lila", "schwarz"]
  ];

  const myWordsBank = mySuperBank[id];
  const colorsBank = ["black", "green", "purple", "red", "blue", "yellow"];
  const sameBank = ["SAME", "ОДИНАКОВЫЕ", "VIENODI", "GLEICH"];
  const differentBank = ["DIFFERENT", "РАЗНЫЕ", "SKIRTINGI", "VERSCHIEDEN"];
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];
  const instructionsBank = [
    "Are COLOR of TEXT and COLOR of shape the same?",
    "Одинаковы ли ЦВЕТ текста и ЦВЕТ фигуры?",
    "Ar teksto SPALVA ir figuros SPALVA yra ta pati?",
    "Sind FARBE vom Text und FARBE der Figur gleich?"
  ];

  const checkKey = e => {
    if (e.keyCode === 37) {
      falsePressed();
    } else if (e.keyCode === 39) {
      truePressed();
    }
  };

  React.useEffect(() => {
    document.onkeydown = checkKey;
    return () => {
      document.onkeydown = undefined;
    };
  });

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const postNewPoints = points => {
    if (points > 0) {
      dispatch(postPoints(points));
    }
  };

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "gameda089ai298a97ss72hd8"
    );
    dispatch(postRecord(15, encryptedAES.toString()));
  };

  const beginGame = () => {
    setCounter(6);
    setLevel(1);
    setMistakes(0);
    setPoints(0);
    setGameRunning(true);
    generateAnswer();
  };

  const generateAnswer = () => {
    if (level > 30) {
      setCounter(19);
    } else if (level > 20) {
      setCounter(10);
    } else if (level > 10) {
      setCounter(8);
    }
    if (Math.random() * 100 > 50) {
      generateTempView(true);
      setAnswer(true);
    } else {
      generateTempView(false);
      setAnswer(false);
    }
  };

  const hideAndShowTempView = () => {
    setIsShowing(false);
    setTimeout(beginCountdown, 1000);
  };

  const generateTempView = myAnswer => {
    tempView = imagesBank[Math.round(Math.random() * (imagesBank.length - 1))];
    tempName =
      myWordsBank[Math.round(Math.random() * (myWordsBank.length - 1))];
    if (myAnswer) {
      tempColor = colorsBank[imagesBank.indexOf(tempView)];
      hideAndShowTempView();
    } else {
      generateFalseColor();
    }
  };

  const generateFalseColor = () => {
    var valued = true;
    while (valued) {
      const randomColor =
        colorsBank[Math.round(Math.random() * (colorsBank.length - 1))];
      if (randomColor !== colorsBank[imagesBank.indexOf(tempView)]) {
        valued = false;
        tempColor = randomColor;
        hideAndShowTempView();
      }
    }
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const beginCountdown = () => {
    setCurrentName(tempName);
    setCurrentColor(tempColor);
    setCurrentView(tempView);
    setIsShowing(true);
    setIsAnswering(true);
  };

  const truePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const falsePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (!answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const mistakeMade = () => {
    if (level > 1) {
      setLevel(1);
      setCounter(6);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        postNewPoints(points - 50);
      }
    } else {
      setTimeout(generateAnswer, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={15}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionsBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}
        <div style={{ width: "100%", display: "inline-block" }}>
          <div
            style={{
              width: "150px",
              display: "inline-block",
              borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "5px",
              borderColor: "#000000",
              height: "150px"
            }}
          >
            {isShowing && (
              <img
                style={{
                  width: "120px",
                  height: "120px",
                  marginTop: "15px",
                  marginBottom: "14px",
                  marginLeft: "15px",
                  marginRight: "15px"
                }}
                src={currentView}
                alt="star"
              />
            )}
          </div>
        </div>
        <div
          style={{
            width: "150px",
            display: "inline-block",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "5px",
            borderColor: "#000000",
            height: "40px",
            marginTop: "15px",
            marginBottom: "10px"
          }}
        >
          <Typography
            style={{ color: currentColor }}
            className={classes.middleText}
            variant="body1"
          >
            {isShowing ? currentName : "!"}
          </Typography>
        </div>
        <div className={classes.buttonBlock}>
          <Button
            className={classes.leftButton}
            size="medium"
            variant="contained"
            color="primary"
            style={{ width: matches ? "40%" : "20%" }}
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              falsePressed();
            }}
          >
            {differentBank[id]}
          </Button>
          <Button
            className={classes.rightButton}
            size="medium"
            variant="contained"
            style={{ width: matches ? "40%" : "20%" }}
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              truePressed();
            }}
          >
            {sameBank[id]}
          </Button>
        </div>
        {!matches && <UseArrowKeys />}
        <Button
          className={classes.beginButton}
          size="medium"
          variant="contained"
          color="primary"
          disabled={isGameRunning}
          style={{ width: matches ? "60%" : "20%" }}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default FifteenthGame;
