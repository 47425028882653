import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GoogleBadge from "../common/GoogleBadge";
import AppleBadge from "../common/AppleBadge";

const useStyles = makeStyles({
  footer: {
    backgroundColor: "rgba(255,255,255, 0.5)",
    width: "100%",
    textAlign: "left",
    padding: "20px",
  },
  first: { fontSize: "1.2em", maxWidth: "440px", marginBottom: "10px" },
  second: { fontWeight: "bold", fontSize: "1.2em" },
  third: {
    fontWeight: "bold",
    marginBottom: "5px",
    textDecoration: "underline",
  },
  policy: {
    fontWeight: "bold",
    fontSize: "1.2em",
    marginBottom: "10px",
    maxWidth: "130px",
    "&:hover": {
      color: "red",
      cursor: "pointer",
    },
  },
  link: {
    cursor: "default",
    "&:hover": {
      textDecoration: "none",
      cursor: "default",
    },
  },
});

const Footer = () => {
  const id = useSelector((store) => store.language.id);
  const matches = useMediaQuery("(max-width:500px)");

  const rightsBank = [
    "All rights reserved",
    "Все права защищены",
    "Visos teisės saugomos",
    "Alle Rechte vorbehalten",
  ];
  const policyBank = [
    "Privacy policy",
    "Политика конфиденциальности",
    "Privatumo politika",
    "Datenschutzerklärung",
  ];

  const infoBank = [
    `If you wish to contact us, the best way is by using our email address below. We try to respond to all inquiries as quickly as possible, usually within 24 hours.`,
    `Если вы хотите связаться с нами, лучший способ - использовать наш адрес электронной почты ниже. Мы постараемся ответить на все ваши вопросы как можно быстрее, обычно в течение 24 часов.`,
    `Jei norite susisiekti su mumis, geriausias būdas yra per mūsų el. pašto adresą žemiau. Į visus klausimus stengiamės atsakyti kuo greičiau, dažniausiai per 24 valandas.`,
    `Wenn Sie mit uns Kontakt aufnehmen möchten, erreichen Sie uns am besten über unsere E-Mail-Adresse unten. Wir bemühen uns, alle Anfragen so schnell wie möglich zu beantworten, in der Regel innerhalb von 24 Stunden.`,
  ];

  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Link to="/policy" className={classes.link}>
        <Typography className={classes.policy} variant="body1">
          {policyBank[id]}
        </Typography>
      </Link>
      <Typography
        className={classes.first}
        style={{ fontSize: matches ? "1em" : "1.2em" }}
        variant="body1"
      >
        {infoBank[id]}
      </Typography>
      <Typography
        className={classes.third}
        style={{ fontSize: matches ? "1em" : "1.4em" }}
        variant="body1"
      >
        uniquebrainsworldwide@gmail.com
      </Typography>
      <AppleBadge /> <br />
      <GoogleBadge />
      <Typography className={classes.second} variant="body1">
        © 2019 Unique Brains {rightsBank[id]}
      </Typography>
    </div>
  );
};
export default Footer;
