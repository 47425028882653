import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Typography, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { postPoints } from "../../state/actions/points";
import { postRecord } from "../../state/actions/record";

import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import UseArrowKeys from "../common/UseArrowKeys";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "20px"
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    fontWeight: "bold"
  },
  leftText: {
    fontWeight: "bold",
    float: "left",
    width: "50%",
    textAlign: "right",
    padding: "5px 40px 5px 0px",
    fontSize: "1.4em"
  },
  rightText: {
    fontWeight: "bold",
    float: "right",
    width: "50%",
    textAlign: "left",
    padding: "5px 0px 5px 40px",
    fontSize: "1.4em"
  },
  block: {
    width: "100%",
    padding: "5px"
  },
  mainBlock: {
    display: "inline-block",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  buttonBlock: {},
  leftButton: {
    width: "20%",
    margin: "5px 20px 5px 0px",
    fontSize: "1.4em",
    background: "red",
    "&:hover": {
      background: "black"
    }
  },
  rightButton: {
    width: "20%",
    margin: "5px 0px 5px 20px",
    fontSize: "1.4em",
    background: "green",
    "&:hover": {
      background: "black"
    }
  },
  beginButton: {
    margin: "20px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  }
});

const FirstGame = () => {
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(4);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const record = useSelector(store => store.record.score);
  const myLevel = useSelector(store => store.points.level);
  const id = useSelector(store => store.language.id);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const [firstTextLeft, setFirstTextLeft] = React.useState("Color");
  const [firstTextRight, setFirstTextRight] = React.useState("Name Of Color");
  const [secondTextLeft, setSecondTextLeft] = React.useState("Color");
  const [secondTextRight, setSecondTextRight] = React.useState("Name Of Color");
  const [thirdTextLeft, setThirdTextLeft] = React.useState("Color");
  const [thirdTextRight, setThirdTextRight] = React.useState("Name Of Color");
  const [firstTextLeftColor, setFirstTextLeftColor] = React.useState("black");
  const [firstTextRightColor, setFirstTextRightColor] = React.useState("black");
  const [secondTextLeftColor, setSecondTextLeftColor] = React.useState("black");
  const [secondTextRightColor, setSecondTextRightColor] = React.useState(
    "black"
  );
  const [thirdTextLeftColor, setThirdTextLeftColor] = React.useState("black");
  const [thirdTextRightColor, setThirdTextRightColor] = React.useState("black");
  const [isAnswering, setIsAnswering] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:500px)");
  const myColorBank = ["red", "yellow", "green", "purple", "black"];
  const mySuperBank = [
    ["red", "yellow", "green", "purple", "black"],
    ["красный", "желтый", "зеленый", "фиолетовый", "черный"],
    ["raudonas", "geltonas", "žalias", "purpurinis", "juodas"],
    ["rot", "gelb", "grün", "lila", "schwarz"]
  ];

  const myWordsBank = mySuperBank[id];

  const instructionBank = [
    `Does COLOR of text on the left side match its NAME(TEXT) on the right side?`,
    `Совпадает ли ЦВЕТ левого текста с ИМЕНЕМ(ТЕКСТОМ) с правой стороны?`,
    `Ar sutampa kairio teksto SPALVA su jos PAVADINIMU(TEKSTU) dešinėje pusėje?`,
    `Passt die FARBE vom linken Text zu ihrem NAMEN(TEXT) auf der rechten Seite?`
  ];
  const trueBank = ["YES", "ДА", "TAIP", "JA"];
  const falseBank = ["NO", "НЕТ", "NE", "NEIN"];
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "gamesuperHash2878eujdada"
    );
    dispatch(postRecord(1, encryptedAES.toString()));
  };

  const postNewPoints = points => {
    dispatch(postPoints(points));
  };

  const checkKey = e => {
    if (e.keyCode === 37) {
      falsePressed();
    } else if (e.keyCode === 39) {
      truePressed();
    }
  };

  React.useEffect(() => {
    document.onkeydown = checkKey;
    return () => {
      document.onkeydown = undefined;
    };
  });

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const beginGame = () => {
    setLevel(1);
    setMistakes(0);
    setPoints(0);
    setCounter(4);
    setGameRunning(true);
    generateAnswer();
  };

  const generateAnswer = () => {
    if (level > 30) {
      setCounter(10);
    } else if (level > 20) {
      setCounter(7);
    } else if (level > 10) {
      setCounter(5);
    }
    if (Math.random() * 100 > 50) {
      setAnswer(true);
      generateValues(true);
    } else {
      setAnswer(false);
      generateValues(false);
    }
  };

  const generateValues = myAnswer => {
    if (level < 6) {
      if (myAnswer) {
        generateFirstValueTrue(true);
      } else {
        generateFirstValueFalse(true);
      }
    } else {
      if (level < 15) {
        if (myAnswer) {
          generateFirstValueTrue(false);
          generateSecondValueTrue(true);
        } else {
          if (Math.random() * 100 > 50) {
            generateFirstValueTrue(false);
            generateSecondValueFalse(true);
          } else {
            generateFirstValueFalse(false);
            generateSecondValueTrue(true);
          }
        }
      } else {
        if (myAnswer) {
          generateFirstValueTrue(false);
          generateSecondValueTrue(false);
          generateThirdValueTrue();
        } else {
          const num = Math.random() * 100;
          if (num < 20) {
            generateFirstValueFalse(false);
            generateSecondValueTrue(false);
            generateThirdValueTrue();
          } else {
            if (num < 40) {
              generateFirstValueTrue(false);
              generateSecondValueTrue(false);
              generateThirdValueFalse();
            } else {
              if (num < 60) {
                generateFirstValueTrue(false);
                generateSecondValueFalse(false);
                generateThirdValueFalse();
              } else {
                if (num < 80) {
                  generateFirstValueTrue(false);
                  generateSecondValueFalse(false);
                  generateThirdValueTrue();
                } else {
                  generateFirstValueFalse(false);
                  generateSecondValueFalse(false);
                  generateThirdValueTrue();
                }
              }
            }
          }
        }
      }
    }
  };

  const generateFirstValueTrue = isEnd => {
    const num = Math.round(Math.random() * (myWordsBank.length - 1));
    const numm = Math.round(Math.random() * (myWordsBank.length - 1));
    const nummm = Math.round(Math.random() * (myWordsBank.length - 1));
    setFirstTextLeftColor(myColorBank[num]);
    setFirstTextRight(myWordsBank[num]);
    setFirstTextLeft(myWordsBank[numm]);
    setFirstTextRightColor(myColorBank[nummm]);
    if (isEnd) {
      beginCountdown();
    }
  };

  const generateSecondValueTrue = isEnd => {
    const num = Math.round(Math.random() * (myWordsBank.length - 1));
    const numm = Math.round(Math.random() * (myWordsBank.length - 1));
    const nummm = Math.round(Math.random() * (myWordsBank.length - 1));
    setSecondTextLeftColor(myColorBank[num]);
    setSecondTextRight(myWordsBank[num]);
    setSecondTextLeft(myWordsBank[numm]);
    setSecondTextRightColor(myColorBank[nummm]);
    if (isEnd) {
      beginCountdown();
    }
  };

  const generateSecondValueFalse = isEnd => {
    const num = Math.round(Math.random() * (myWordsBank.length - 1));
    var valued = true;
    while (valued) {
      const numm = Math.round(Math.random() * (myWordsBank.length - 1));
      if (numm !== num) {
        setSecondTextLeftColor(myColorBank[num]);
        setSecondTextRight(myWordsBank[numm]);
        const nummm = Math.round(Math.random() * (myWordsBank.length - 1));
        const nummmm = Math.round(Math.random() * (myWordsBank.length - 1));
        setSecondTextLeft(myWordsBank[nummmm]);
        setSecondTextRightColor(myColorBank[nummm]);
        valued = false;
        if (isEnd) {
          beginCountdown();
        }
      }
    }
  };

  const generateThirdValueTrue = () => {
    const num = Math.round(Math.random() * (myWordsBank.length - 1));
    const numm = Math.round(Math.random() * (myWordsBank.length - 1));
    const nummm = Math.round(Math.random() * (myWordsBank.length - 1));
    setThirdTextLeftColor(myColorBank[num]);
    setThirdTextRight(myWordsBank[num]);
    setThirdTextLeft(myWordsBank[numm]);
    setThirdTextRightColor(myColorBank[nummm]);
    beginCountdown();
  };

  const generateThirdValueFalse = () => {
    const num = Math.round(Math.random() * (myWordsBank.length - 1));
    var valued = true;
    while (valued) {
      const numm = Math.round(Math.random() * (myWordsBank.length - 1));
      if (numm !== num) {
        setThirdTextLeftColor(myColorBank[num]);
        setThirdTextRight(myWordsBank[numm]);
        const nummm = Math.round(Math.random() * (myWordsBank.length - 1));
        const nummmm = Math.round(Math.random() * (myWordsBank.length - 1));
        setThirdTextLeft(myWordsBank[nummmm]);
        setThirdTextRightColor(myColorBank[nummm]);
        valued = false;
        beginCountdown();
      }
    }
  };

  const generateFirstValueFalse = isEnd => {
    const num = Math.round(Math.random() * (myWordsBank.length - 1));
    var valued = true;
    while (valued) {
      const numm = Math.round(Math.random() * (myWordsBank.length - 1));
      if (numm !== num) {
        setFirstTextLeftColor(myColorBank[num]);
        setFirstTextRight(myWordsBank[numm]);
        const nummm = Math.round(Math.random() * (myWordsBank.length - 1));
        const nummmm = Math.round(Math.random() * (myWordsBank.length - 1));
        setFirstTextLeft(myWordsBank[nummmm]);
        setFirstTextRightColor(myColorBank[nummm]);
        valued = false;
        if (isEnd) {
          beginCountdown();
        }
      }
    }
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const beginCountdown = () => {
    setIsAnswering(true);
  };

  const truePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer) {
          setPoints(points + 50 + level * 10);
          setLevel(oldValue => {
            return oldValue + 1;
          });
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const falsePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (!answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const mistakeMade = () => {
    if (level > 1) {
      setLevel(1);
      setCounter(4);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        if (points - 50 > 0) {
          postNewPoints(points - 50);
        }
      }
    } else {
      setTimeout(generateAnswer, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={1}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />

      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <p
          className={classes.hint}
          style={{ fontSize: matches ? "1.2em" : "1.5em" }}
        >
          {instructionBank[id]}
        </p>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}
        <div
          className={classes.mainBlock}
          style={{
            width: matches ? "90%" : "50%",
            margin: matches ? "0px 10px" : "10px 40px 10px 40px"
          }}
        >
          <div className={classes.block}>
            <Typography
              className={classes.leftText}
              style={{
                color: firstTextLeftColor,
                fontSize: matches ? "1.1em" : "1.4em",

                padding: matches ? "5px" : "5px 40px 5px 0px"
              }}
              variant="body1"
            >
              {firstTextLeft}
            </Typography>
            <Typography
              className={classes.rightText}
              variant="body1"
              style={{
                color: firstTextRightColor,
                fontSize: matches ? "1.1em" : "1.4em",

                padding: matches ? "5px" : "5px 0px 5px 40px"
              }}
            >
              {firstTextRight}
            </Typography>
          </div>
          {level > 6 && (
            <div className={classes.block}>
              <Typography
                className={classes.leftText}
                variant="body1"
                style={{
                  color: secondTextLeftColor,
                  fontSize: matches ? "1.1em" : "1.4em",
                  padding: matches ? "5px" : "5px 40px 5px 0px"
                }}
              >
                {secondTextLeft}
              </Typography>
              <Typography
                className={classes.rightText}
                variant="body1"
                style={{
                  color: secondTextRightColor,
                  fontSize: matches ? "1.1em" : "1.4em",
                  padding: matches ? "5px" : "5px 0px 5px 40px"
                }}
              >
                {secondTextRight}
              </Typography>
            </div>
          )}
          {level > 15 && (
            <div className={classes.block}>
              <Typography
                className={classes.leftText}
                variant="body1"
                style={{
                  color: thirdTextLeftColor,
                  fontSize: matches ? "1.1em" : "1.4em",
                  padding: matches ? "5px" : "5px 40px 5px 0px"
                }}
              >
                {thirdTextLeft}
              </Typography>
              <Typography
                className={classes.rightText}
                variant="body1"
                style={{
                  color: thirdTextRightColor,
                  fontSize: matches ? "1.1em" : "1.4em",
                  padding: matches ? "5px" : "5px 0px 5px 40px"
                }}
              >
                {thirdTextRight}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.buttonBlock}>
          <Button
            className={classes.leftButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              falsePressed();
            }}
          >
            {falseBank[id]}
          </Button>
          <Button
            className={classes.rightButton}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              truePressed();
            }}
          >
            {trueBank[id]}
          </Button>
        </div>
        {!matches && <UseArrowKeys id={id} />}
        <Button
          className={classes.beginButton}
          style={{ width: matches ? "50%" : "20%" }}
          size="medium"
          variant="contained"
          color="primary"
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default FirstGame;
