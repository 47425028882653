import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import GridItem from "../GamesList/GridItem";
import { Container } from "@material-ui/core";
import maincolors from "../common/main/maincolors.png";
import maincolorsandshapes from "../common/main/maincolorsandshapes.png";
import maincompare from "../common/main/maincompare.jpg";
import mainmaths from "../common/main/mainmaths.jpg";
import mainphotofigures from "../common/main/mainphotofigures.png";
import memorymain from "../common/main/memorymain.jpg";
import letternumbermain from "../common/main/letternumbermain.jpg";
import { descriptionData } from "../common/descriptionData";

const RankingsPage = () => {
  const id = useSelector(store => store.language.id);
  const user = useSelector(store => store.user.user);
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (!user) {
      history.push("/sign-in");
    }
  }, [user, history]);

  const linkData = [
    "/rankings/1",
    "/rankings/2",
    "/rankings/3",
    "/rankings/4",
    "/rankings/5",
    "/rankings/6",
    "/rankings/7",
    "/rankings/8",
    "/rankings/9",
    "/rankings/10",
    "/rankings/11",
    "/rankings/12",
    "/rankings/13",
    "/rankings/14",
    "/rankings/15"
  ];
  const nameData = [
    "Rising Colors",
    "Letter/Number",
    "Just Remember",
    "Sharp eyes",
    "Remember the number",
    "Compare",
    "Correctly?",
    "Mental counting",
    "Number agility",
    "Simple counting",
    "Mark the last",
    "Single Color",
    "Repeat the sequence",
    "Remember previous",
    "Color of shape"
  ];
  const imageData = [
    maincolors,
    letternumbermain,
    memorymain,
    mainphotofigures,
    memorymain,
    maincompare,
    maincompare,
    mainmaths,
    mainmaths,
    mainmaths,
    memorymain,
    maincolors,
    memorymain,
    memorymain,
    maincolorsandshapes
  ];
  const hardnessData = [
    "HARD",
    "MID",
    "MID",
    "MID",
    "EASY",
    "EASY",
    "HARD",
    "MID",
    "HARD",
    "EASY",
    "HARD",
    "EASY",
    "HARD",
    "EASY",
    "EASY"
  ];

  const rankingsBank = ["Rankings", "Рейтинг", "Reitingas", "Rankenliste"];

  return (
    <Container maxWidth="md">
      <h1 style={{ textAlign: "center", color: "rgba(255, 255, 255, 0.9)" }}>
        {rankingsBank[id]}
      </h1>
      <Grid container spacing={1}>
        {linkData.map(item => {
          return (
            <GridItem
              name={nameData[linkData.indexOf(item)]}
              hardness={hardnessData[linkData.indexOf(item)]}
              description={descriptionData[linkData.indexOf(item)][id]}
              image={imageData[linkData.indexOf(item)]}
              link={item}
              key={linkData.indexOf(item)}
            />
          );
        })}
      </Grid>
    </Container>
  );
};
export default RankingsPage;
