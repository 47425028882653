import {
  VERIFY_USER,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  DISPLAY_USER_ERROR,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILED,
  SIGN_OUT,
  SUBMIT_PASSWORD,
  SUBMIT_PASSWORD_SUCCESS,
  SUBMIT_PASSWORD_FAILED,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  HIDE_SIGNUP_ERROR,
  ADD_USER,
  USER,
  REMOVE_SUBMIT_PASSWORD_MESSAGE,
  REMOVE_VERIFY_EMAIL_MESSAGE,
  HIDE_SIGNIN_ERROR
} from "../actionTypes/user";

const initialState = {
  user: undefined,
  signInError: undefined,
  verifyEmailError: undefined,
  verifyEmailSuccess: undefined,
  passwordSubmitSucces: undefined,
  isLoading: undefined,
  passwordSubmitError: undefined,
  signUpError: undefined,
  createStatus: undefined,
  isSubmiting: undefined
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {
    case USER: {
      return {
        ...state,
        user: action.payload,
        signInError: undefined,
        verifyEmailError: undefined,
        verifyEmailSuccess: undefined,
        isLoading: undefined,
        passwordSubmitError: undefined,
        signUpError: undefined,
        createStatus: undefined
      };
    }
    case ADD_USER: {
      return {
        ...state,
        user: action.user
      };
    }
    case SUBMIT_PASSWORD: {
      return {
        ...state,
        passwordSubmitError: undefined,
        passwordSubmitSucces: undefined,
        isSubmiting: true
      };
    }
    case SUBMIT_PASSWORD_FAILED: {
      return {
        ...state,
        passwordSubmitError: true,
        isSubmiting: undefined
      };
    }
    case SUBMIT_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordSubmitSucces: true,
        isSubmiting: undefined
      };
    }
    case REMOVE_SUBMIT_PASSWORD_MESSAGE: {
      return {
        ...state,
        passwordSubmitError: undefined,
        passwordSubmitSucces: undefined
      };
    }
    case VERIFY_USER: {
      return {
        ...state,
        verifyEmailError: undefined,
        verifyEmailSuccess: undefined,
        signInError: undefined,
        isSubmiting: true
      };
    }
    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
        isAdmin: true,
        isSubmiting: undefined
      };
    }
    case VERIFY_USER_FAILED: {
      return {
        ...state,
        signInError: true,
        isLoading: undefined,
        isSubmiting: undefined
      };
    }
    case HIDE_SIGNIN_ERROR: {
      return {
        ...state,
        signInError: undefined
      };
    }
    case HIDE_SIGNUP_ERROR: {
      return { ...state, signUpError: undefined };
    }
    case CREATE_USER: {
      return {
        ...state,
        isLoading: true,
        signUpError: undefined,
        isSubmiting: true
      };
    }

    case VERIFY_EMAIL: {
      return {
        ...state,
        verifyEmailError: undefined,
        signInError: undefined,
        verifyEmailSuccess: undefined,
        isSubmiting: true
      };
    }

    case VERIFY_EMAIL_SUCCESS: {
      return { ...state, verifyEmailSuccess: true, isSubmiting: undefined };
    }
    case VERIFY_EMAIL_FAILED: {
      return { ...state, verifyEmailError: true, isSubmiting: undefined };
    }
    case REMOVE_VERIFY_EMAIL_MESSAGE: {
      return {
        ...state,
        verifyEmailError: undefined,
        verifyEmailSuccess: undefined
      };
    }
    case DISPLAY_USER_ERROR: {
      return { ...state, signInError: true };
    }

    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
        signUpError: undefined,
        isSubmiting: undefined
      };
    }

    case CREATE_USER_FAILED: {
      return {
        ...state,
        signUpError: true,
        isLoading: undefined,
        isSubmiting: undefined
      };
    }

    case SIGN_OUT: {
      sessionStorage.removeItem("token");
      return {
        ...state,
        user: undefined,
        signInError: undefined,
        emailError: undefined,
        restorePassword: undefined,
        signUpError: undefined,
        isLoading: undefined
      };
    }

    default: {
      return state;
    }
  }
}
