import React from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "20px"
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    cursor: "pointer",
    maxWidth: 400,
    background: "rgba(255, 255, 255, 0.9)"
  },
  img: {
    width: 128,
    height: 128,
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  }
}));

const GridItem = ({ name, hardness, link, description, image }) => {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery("(max-width:500px)");

  const open = () => {
    history.push(`${link}`);
  };

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        elevation={4}
        onClick={open}
        style={{
          marginLeft: matches ? "20px" : "",
          marginRight: matches ? "20px" : ""
        }}
      >
        <Grid container spacing={2}>
          <Grid item style={{ width: matches ? "100%" : "" }}>
            <img className={classes.img} alt={`${name}`} src={image} />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  style={{ fontWeight: "bold" }}
                  gutterBottom
                  variant="subtitle1"
                >
                  {name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "bold",
                  color:
                    hardness === "HARD"
                      ? "red"
                      : hardness === "EASY"
                      ? "green"
                      : "blue"
                }}
              >
                {hardness}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
GridItem.propTypes = {
  name: PropTypes.string,
  hardness: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
};
export default GridItem;
