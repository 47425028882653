import { CHANGE_LANGUAGE } from "../actionTypes/language";

const initialState = {
  id: 0
};

export default function languageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      return { ...state, id: action.id };
    }
    default: {
      return state;
    }
  }
}
