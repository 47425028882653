import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Container, Card, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import evolution from "./evolution.jpg";
import ScreenshotCarousel from "./ScreenshotCarousel";
import GoogleBadge from "../common/GoogleBadge";
import AppleBadge from "../common/AppleBadge";

const useStyles = makeStyles({
  title: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    textShadow: "2px 2px black",
    borderBottomColor: "black",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
  },
  first: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    textShadow: "2px 2px black",
  },
  second: {
    margin: "20px 100px",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    textShadow: "2px 2px black",
  },
  secondSecond: {
    fontWeight: "bold",
    textShadow: "2px 2px black",
  },
  third: {
    fontSize: "2em",
    margin: "20px 100px",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    textShadow: "2px 2px black",
  },
});

const Home = () => {
  const classes = useStyles();
  const id = useSelector((store) => store.language.id);
  const user = useSelector((store) => store.user.user);
  const matches = useMediaQuery("(max-width:670px)");

  const history = useHistory();

  const titleBank = [
    "Home page",
    "Главная страница",
    "Pagrindinis puslapis",
    "Hauptseite",
  ];
  const firstText = [
    "Free games to develop your intellect",
    "Бесплатные игры для вашего интеллекта",
    "Nemokami žaidimai jūsų intelektui",
    "Kostenlose Spiele für Ihre Intelligenz",
  ];
  const secondText = [
    "Our games will improve your memory, speed and flexibility of thinking,",
    "Наши игры улучшат вашу память, скорость и пластичность мышления,",
    "Mūsų žaidimai pagerins jūsų atmintį, mąstymo greitį ir plastiškumą,",
    "Unsere spiele verbessern Ihr Gedächtnis, Geschwindigkeit und Plastizität des Denkens,",
  ];
  const thirdText = [
    "and additionally counting skills, attention and concentration.",
    "и в дополнение навыки математики, внимание и концентрацию.",
    "taip pat matematikos įgūdžius, dėmesį ir susikaupimą.",
    "und dazu noch mathematische Fähigkeiten, Fokus und Konzentration.",
  ];
  // const fourthText = [
  //   "You can watch a video to see how they function:",
  //   "Вы можете посмотреть видео, чтобы увидеть, как они функционируют:",
  //   "Galite pažiūrėti vaizdo įrašą ir sužinoti, kaip jie veikia:",
  //   "Sie können sich das Video angucken, um zu sehen, wie sie funktionieren:"
  // ];
  const buttonText = [
    "Register and start playing",
    "Зарегестрироваться и начать играть",
    "Užsiregistruoti ir pradėti žaisti",
    "Registrieren und anfangen zu spielen",
  ];

  const answering = [
    "Every game is becoming more and more difficult with every right answer, so you can constantly improve yourself and there is no limit of hardness.",
    "Каждая игра становится все сложнее и сложнее с каждым правильным ответом, поэтому вы можете постоянно развиваться и у уровня сложности нет предела.",
    "Kiekvienas žaidimas tampa vis sudėtingesnis su kiekvienu teisingu atsakymu, todėl galite nuolat tobulėti ir taip pat nėra sudetingumo ribų.",
    "Jedes Spiel wird mit jeder richtigen Antwort immer schwieriger, sodass Sie sich ständig verbessern können und es keine Grenzen für die Schwierigkeit gibt.",
  ];
  const levelingTitle = [
    "Leveling System",
    "Уровневая система",
    "Levelinimas",
    "Stufen",
  ];
  const easyGames = [
    "EASY Games - Available from 1st Level, are not very hard and are mainly aimed to improve duration of your ability to concentrate yourself.",
    "EASY Игры - Доступны с 1-го уровня, не очень сложны и в основном направлены на увеличение продолжительности вашей способности концентрироваться",
    "EASY Žaidimai - Atidaryti nuo 1-ojo lygio, nėra labai sunkūs ir daugiausia skirti pagerinti jūsų sugebėjimo susikaupti trukmę.",
    "EASY Spiele - Erhältlich ab dem 1. Level, sind nicht sehr schwer und zielen hauptsächlich darauf ab, die Dauer Ihrer Konzentrationsfähigkeit zu verbessern.",
  ];
  const midGames = [
    "MID Games - Available from 6th Level, are much harder and are aimed to improve different skills.",
    "MID Игры - Доступны с 6-го уровня, намного сложнее и направлены на улучшение различных навыков",
    "MID Žaidimai - Atidaryti nuo 6-ojo lygio, yra daug sunkesni ir skirti patobulinti įvairius įgūdžius.",
    "MID Spiele - Erhältlich ab der 6. Stufe, sind viel schwieriger und zielen darauf ab, verschiedene Fähigkeiten zu verbessern.",
  ];
  const hardGames = [
    "HARD Games - Available from 10th Level, are very hard and require very fast problem solving skills.",
    "HARD игры - Доступны с 10-го уровня, очень сложны и требуют очень быстрых навыков решения проблем.",
    "HARD Žaidimai - Atidaryti nuo 10-ojo lygio, yra labai sunkūs ir reikalauja labai gerų problemų sprendimo įgūdžių.",
    "HARD Spiele - Verfügbar ab dem 10. Level, sind sehr schwierig und erfordern sehr schnelle Fähigkeiten zur Problemlösung.",
  ];
  const rankingsTitle = [
    "Rankings",
    "Список лидеров",
    "Reitingas",
    "Rankenliste",
  ];
  const rankingsGames = [
    "As you reach Maximum Level (10), you are able to compete with other players and get into Rankings!",
    "Достигнув максимального уровня (10), вы сможете соревноваться с другими игроками и попасть в рейтинг!",
    "Kai pasieksite maksimalų lygį (10), galėsite konkuruoti su kitais žaidėjais ir patekti į reitingą!",
    "Wenn Sie die maximale Stufe (10) erreichen, können Sie sich mit anderen Spielern messen und in die Rangliste aufsteigen!",
  ];

  return (
    <Container maxWidth="md">
      <h1
        style={{
          textAlign: "center",
          color: "rgba(255, 255, 255, 0.9)",
          fontStyle: "italic",
        }}
      >
        {titleBank[id]}
      </h1>
      <Card
        style={{
          background: "rgba(171, 190, 231, 0.5)",
          borderRadius: "10px",
          marginBottom: matches ? "10px" : "20px",
        }}
      >
        <Typography
          className={classes.first}
          style={{
            fontSize: matches ? "1.5em" : "2.5em",
            marginTop: matches ? "10px" : "20px",
            marginBottom: matches ? "5px" : "20px",
          }}
          variant="body1"
        >
          {firstText[id]}
        </Typography>

        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            alt="2"
            style={{
              maxHeight: "300px",
              width: "80%",
              borderRadius: "20px",
              opacity: "0.7",
            }}
            src={evolution}
          />
        </div>
        <div
          className={classes.second}
          style={{
            fontSize: matches ? "1.2em" : "2em",
            margin: matches ? "10px 40px" : "20px 100px",
          }}
          variant="body1"
        >
          {secondText[id] + " " + thirdText[id]}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <AppleBadge />
        </div>
        <div
          style={{ width: "100%", textAlign: "center", paddingLeft: "19px" }}
        >
          <GoogleBadge />
        </div>
        <div
          className={classes.second}
          style={{
            fontSize: matches ? "1em" : "1.6em",
            margin: matches ? "10px 40px" : "20px 100px",
            color: "#045d73",
          }}
          variant="body1"
        >
          {answering[id]}
        </div>
        <ScreenshotCarousel />
        <div style={{ textAlign: "center" }}>
          <span
            className={classes.title}
            style={{
              fontSize: matches ? "1.5em" : "2.5em",
              paddingBottom: matches ? "3px" : "7px",
            }}
          >
            {levelingTitle[id]}
          </span>
        </div>
        <div
          className={classes.second}
          style={{
            fontSize: matches ? "1em" : "1.5em",
            margin: matches ? "10px 40px" : "20px 100px",
          }}
          variant="body1"
        >
          {easyGames[id]}
        </div>
        <div
          className={classes.second}
          style={{
            fontSize: matches ? "1em" : "1.5em",
            margin: matches ? "10px 40px" : "20px 100px",
            color: "#045d73",
          }}
          variant="body1"
        >
          {midGames[id]}
        </div>
        <div
          className={classes.second}
          style={{
            fontSize: matches ? "1em" : "1.5em",
            margin: matches ? "10px 40px" : "20px 100px",
          }}
          variant="body1"
        >
          {hardGames[id]}
        </div>
        <div style={{ textAlign: "center" }}>
          <span
            className={classes.title}
            style={{
              fontSize: matches ? "1.5em" : "2.5em",
              paddingBottom: matches ? "3px" : "7px",
            }}
          >
            {rankingsTitle[id]}
          </span>
        </div>
        <div
          className={classes.second}
          style={{
            fontSize: matches ? "1.2em" : "2em",
            margin: matches ? "10px 40px" : "20px 100px",
            color: "#045d73",
          }}
          variant="body1"
        >
          {rankingsGames[id]}
        </div>
        {!user && (
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                marginTop: "0px",
                marginBottom: matches ? "10px" : "20px",
                width: "80%",
                borderRadius: "10px",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: matches ? "1.2em" : "1.4em",
                background: "#7818a3",
              }}
              size="medium"
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                history.push("/sign-up");
              }}
            >
              {buttonText[id]}
            </Button>
          </div>
        )}
      </Card>
    </Container>
  );
};
export default Home;
