import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles, Typography, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";

import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";
import UseArrowKeys from "../common/UseArrowKeys";

const useStyles = makeStyles({
  middleText: {
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    paddingTop: "5px",
    fontSize: "1.4em"
  },
  buttonBlock: {
    marginBottom: "15px",
    marginTop: "30px"
  },
  block: {
    display: "flex",
    marginBottom: "15px",
    marginTop: "15px",
    width: "100%",
    padding: "5px"
  },
  leftButton: {
    width: "25%",
    margin: "5px 20px 5px 0px",
    fontSize: "1.4em",
    background: "red",
    "&:hover": {
      background: "black"
    }
  },
  rightButton: {
    width: "25%",
    margin: "5px 0px 5px 20px",
    fontSize: "1.4em",
    background: "green",
    "&:hover": {
      background: "black"
    }
  },
  beginButton: {
    width: "20%",
    margin: "20px 10px 0px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

const TwelvethGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(4);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [currentColor, setCurrentColor] = React.useState("yellow");
  const [currentName, setCurrentName] = React.useState("yellow");
  const [isShowing, setIsShowing] = React.useState(true);
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:650px)");
  const mySuperBank = [
    ["yellow", "yellow", "green", "red"],
    ["желтый", "желтый", "зеленый", "красный"],
    ["geltonas", "geltonas", "žalias", "raudonas"],
    ["gelb", "gelb", "grün", "rot"]
  ];

  var tempColor = "yellow";
  var tempName = "yellow";
  const classes = useStyles();

  const wordsBank = mySuperBank[id];
  const colorsBank = ["yellow", "yellow", "green", "red"];

  const sameBank = ["SAME", "ОДИНАКОВЫЕ", "VIENODI", "GLEICH"];
  const differentBank = ["DIFFERENT", "РАЗНЫЕ", "SKIRTINGI", "VERSCHIEDEN"];
  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];
  const instructionsBank = [
    "Are color and meaning of word same ?",
    "Одинаковы ли цвет и значение слова ?",
    "Ar žodžio spalva ir reikšmė yra ta pati ?",
    "Sind Farbe und Wortbedeutung gleich ?"
  ];

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "gamedad9820703290jkda9ds"
    );
    dispatch(postRecord(12, encryptedAES.toString()));
  };

  const postNewPoints = points => {
    if (points > 0) {
      dispatch(postPoints(points));
    }
  };

  const checkKey = e => {
    if (e.keyCode === 37) {
      falsePressed();
    } else if (e.keyCode === 39) {
      truePressed();
    }
  };

  React.useEffect(() => {
    document.onkeydown = checkKey;
    return () => {
      document.onkeydown = undefined;
    };
  });

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [isFirstRun, setFirstRun]);

  const beginGame = () => {
    setLevel(1);
    setMistakes(0);
    setCounter(4);
    setPoints(0);
    setGameRunning(true);
    generateAnswer();
  };

  const generateAnswer = () => {
    if (level > 30) {
      setCounter(10);
    } else if (level > 20) {
      setCounter(7);
    } else if (level > 10) {
      setCounter(5);
    }
    if (Math.random() * 100 > 50) {
      setAnswer(true);
      generateView(true);
    } else {
      setAnswer(false);
      generateView(false);
    }
  };

  const generateView = myAnswer => {
    tempColor = colorsBank[Math.round(Math.random() * (colorsBank.length - 1))];
    if (myAnswer) {
      tempName = wordsBank[colorsBank.indexOf(tempColor)];
      hideAndShowTempName();
    } else {
      generateFalseName();
    }
  };

  const generateFalseName = () => {
    var valued = true;
    while (valued) {
      const randomName =
        wordsBank[Math.round(Math.random() * (wordsBank.length - 1))];
      if (wordsBank.indexOf(randomName) !== colorsBank.indexOf(tempColor)) {
        valued = false;
        tempName = randomName;
        hideAndShowTempName();
      }
    }
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const hideAndShowTempName = () => {
    setIsShowing(false);
    setTimeout(beginCountdown, 1000);
  };

  const beginCountdown = () => {
    setCurrentName(tempName);
    setCurrentColor(tempColor);
    setIsShowing(true);
    setIsAnswering(true);
  };

  const truePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const falsePressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (!answer) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer();
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const mistakeMade = () => {
    if (level > 1) {
      setLevel(1);
      setCounter(4);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        postNewPoints(points - 50);
      }
    } else {
      setTimeout(generateAnswer, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={12}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionsBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}

        <div
          style={{
            width: "150px",
            display: "inline-block",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "5px",
            borderColor: "#000000",
            height: "40px",
            marginTop: "15px",
            marginBottom: "10px"
          }}
        >
          <Typography
            style={{ color: currentColor }}
            className={classes.middleText}
            variant="body1"
          >
            {isShowing ? currentName : "!"}
          </Typography>
        </div>

        <div className={classes.buttonBlock}>
          <Button
            className={classes.leftButton}
            style={{ width: matches ? "40%" : "20%" }}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              falsePressed();
            }}
          >
            {differentBank[id]}
          </Button>
          <Button
            className={classes.rightButton}
            style={{ width: matches ? "40%" : "20%" }}
            size="medium"
            variant="contained"
            color="primary"
            disabled={!isGameRunning}
            onClick={e => {
              e.preventDefault();
              truePressed();
            }}
          >
            {sameBank[id]}
          </Button>
        </div>
        {!matches && <UseArrowKeys />}
        <Button
          className={classes.beginButton}
          size="medium"
          variant="contained"
          style={{ width: matches ? "60%" : "20%" }}
          color="primary"
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default TwelvethGame;
