import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const MyTextField = ({
  onChange,
  placeholderText,
  helperText,
  isDisabled,
  userAnswer
}) => {
  const matches = useMediaQuery("(max-width:400px)");

  const makeFocus = input => {
    if (input && !isDisabled && !matches) {
      input.focus();
    }
  };

  return (
    <TextField
      autoFocus
      style={{ marginTop: "20px", width: "70%" }}
      fullWidth
      value={userAnswer}
      disabled={isDisabled}
      variant="outlined"
      placeholder={placeholderText}
      helperText={helperText}
      inputRef={makeFocus}
      onInput={e => {
        e.persist();
        onChange(e.target.value);
      }}
    />
  );
};

MyTextField.propTypes = {
  placeholderText: PropTypes.string,
  helperText: PropTypes.string,
  userAnswer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};
export default MyTextField;
