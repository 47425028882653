import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Typography, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import MyTextField from "../common/MyTextField";

import UserStats from "../common/UserStats";
import MyLinearProgress from "../common/MyLinearProgress";

import { postRecord } from "../../state/actions/record";
import { postPoints } from "../../state/actions/points";
import KeyboardLayout from "../common/KeyboardLayout";

const useStyles = makeStyles({
  card: {
    margin: "20px 0px",
    padding: "20px"
  },
  middleText: {
    fontWeight: "bold",
    width: "80%",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    height: "40%",
    verticalAlign: "middle"
  },
  buttonBlock: {
    marginBottom: "15px",
    marginTop: "30px"
  },
  rightText: {
    fontWeight: "bold",
    width: "20%",
    textAlign: "center",
    height: "40px",
    marginRight: "auto",
    marginLeft: "20px",
    padding: "5px 0px 5px 0px",
    fontSize: "1.4em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#000000"
  },
  submitButton: {
    width: "25%",
    margin: "5px 0px 5px 0px",
    background: "green",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  },
  hint: {
    display: "inline-block",
    padding: "5px 15px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "#808080",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  beginButton: {
    width: "20%",
    margin: "10px 10px 10px 10px",
    fontSize: "1.4em",
    "&:hover": {
      background: "black"
    }
  }
});

const FifthGame = () => {
  const myLevel = useSelector(store => store.points.level);
  const [isFirstRun, setFirstRun] = React.useState(true);
  const [counter, setCounter] = React.useState(4);
  const [isGameRunning, setGameRunning] = React.useState(false);
  const [level, setLevel] = React.useState(1);
  const [answer, setAnswer] = React.useState(undefined);
  const [mistakes, setMistakes] = React.useState(0);
  const [points, setPoints] = React.useState(0);
  const id = useSelector(store => store.language.id);
  const record = useSelector(store => store.record.score);
  const [isAnswering, setIsAnswering] = React.useState(false);
  const [answerText, setAnswerText] = React.useState("1337");
  const [userAnswer, setUserAnswer] = React.useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:650px)");

  const beginBank = ["BEGIN", "НАЧАТЬ", "PRADĖTI", "BEGINNEN"];
  const submitBank = ["ANSWER", "ОТВЕТИТЬ", "ATSAKYTI", "ANTWORTEN"];
  const answerBank = [
    "Type your answer here",
    "Введите здесь ваш ответ",
    "Įrašykite čia savo atsakymą",
    "Geben Sie hier Ihre Antwort ein"
  ];
  const instructionBank = [
    "Repeat the appeared number",
    "Повторите появившиеся число",
    "Pakartokite pasirodžiusį numerį",
    "Wiederholen Sie die angezeigte Nummer"
  ];

  React.useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
      window.scrollTo(0, 0);
    }
  }, [setFirstRun, isFirstRun]);

  const postNewPoints = points => {
    if (points > 0) {
      dispatch(postPoints(points));
    }
  };

  const postNewRecord = record => {
    const encryptedAES = CryptoJS.AES.encrypt(
      `${record}`,
      "game28dad9spklm22329ujsa"
    );
    dispatch(postRecord(5, encryptedAES.toString()));
  };

  const beginGame = () => {
    setLevel(1);
    setMistakes(0);
    setPoints(0);
    setCounter(4);
    setGameRunning(true);
    generateAnswer(1);
  };

  const generateAnswer = superski => {
    if (superski > 30) {
      setCounter(10);
    } else if (superski > 20) {
      setCounter(7);
    } else if (superski > 10) {
      setCounter(5);
    }
    var number = 1;
    for (var i = 0; i < superski + 3; i++) {
      number *= 10;
    }
    var valued = true;
    while (valued) {
      const answer = Math.round(Math.random() * (number - 1));
      if (answer > number / 10) {
        valued = false;
        setAnswerText(`${answer}`);
        setAnswer(`${answer}`);
        setTimeout(beginCountdown, 2500);
      }
    }
  };

  const onComplete = () => {
    setIsAnswering(false);
    setTimeout(mistakeMade, 100);
  };

  const beginCountdown = () => {
    setAnswerText("");
    setUserAnswer("");
    setIsAnswering(true);
  };

  const submitPressed = () => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer === userAnswer) {
          setLevel(level + 1);
          setPoints(points + 50 + level * 10);
          setUserAnswer("");
          generateAnswer(level + 1);
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  const mistakeMade = () => {
    if (level > 1) {
      setLevel(1);
      setCounter(4);
    }
    if (mistakes === 2) {
      setGameRunning(false);
      if (myLevel >= 10) {
        if (record < points - 50) {
          postNewRecord(points - 50);
        }
      } else {
        postNewPoints(points - 50);
      }
    } else {
      setTimeout(() => {
        generateAnswer(1);
      }, 100);
    }
    setPoints(points - 50);
    setMistakes(mistakes + 1);
  };

  const mobileSubmitPressed = superNumber => {
    if (isAnswering) {
      setIsAnswering(false);
      setTimeout(() => {
        if (answer === `${superNumber}`) {
          setPoints(points + 50 + level * 10);
          setLevel(level + 1);
          generateAnswer(level + 1);
        } else {
          mistakeMade();
        }
      }, 100);
    }
  };

  return (
    <div>
      <UserStats
        points={points}
        gameId={5}
        showFirstHeart={mistakes < 1}
        showSecondHeart={mistakes < 2}
        showThirdHeart={mistakes < 3}
      />
      <div
        style={{ width: "100%", textAlign: "center", display: "inline-block" }}
      >
        <h2 className={classes.hint}>{instructionBank[id]}</h2>
        {!isAnswering && (
          <div style={{ height: "20px", width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={0}
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            />
          </div>
        )}
        {isAnswering && (
          <MyLinearProgress onComplete={onComplete} counter={counter} />
        )}
        <div
          style={{
            display: "inline-block",
            width: "70%",
            height: matches ? "120px" : "250px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "5px",
            borderColor: "#000000"
          }}
        >
          <Typography
            className={classes.middleText}
            variant="body1"
            style={{
              fontSize: matches ? "1.1em" : "1.5em",
              marginTop: matches ? "40px" : "80px"
            }}
          >
            {answerText}
          </Typography>
        </div>
        {matches && !isAnswering && <KeyboardLayout isDisabled={false} />}
        {matches && isAnswering && (
          <KeyboardLayout
            isDisabled={isAnswering}
            submit={mobileSubmitPressed}
          />
        )}
        {!matches && (
          <form>
            <MyTextField
              userAnswer={userAnswer}
              onChange={setUserAnswer}
              placeholderText={answerBank[id]}
              isDisabled={!isAnswering}
            />

            <div className={classes.buttonBlock}>
              <Button
                className={classes.submitButton}
                size="medium"
                variant="contained"
                type="submit"
                color="primary"
                style={{ width: matches ? "60%" : "20%" }}
                disabled={!isGameRunning}
                onClick={e => {
                  e.preventDefault();
                  submitPressed();
                }}
              >
                {submitBank[id]}
              </Button>
            </div>
          </form>
        )}
        <Button
          className={classes.beginButton}
          size="medium"
          variant="contained"
          style={{ width: matches ? "60%" : "20%" }}
          color="primary"
          disabled={isGameRunning}
          onClick={e => {
            e.preventDefault();
            beginGame();
          }}
        >
          {beginBank[id]}
        </Button>
      </div>
    </div>
  );
};
export default FifthGame;
