import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";

const MyLinearProgress = ({ onComplete, counter }) => {
  const [completed, setCompleted] = React.useState(0);
  React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          clearInterval(timer);
          onComplete();
          return 0;
        }
        return Math.min(oldCompleted + counter, 100);
      });
    }

    const timer = setInterval(progress, 300);
    return () => {
      clearInterval(timer);
    };
  }, [onComplete, counter]);
  return (
    <div style={{ height: "20px", width: "100%" }}>
      <LinearProgress
        variant="determinate"
        value={completed}
        color="primary"
        style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
      />
    </div>
  );
};
MyLinearProgress.propTypes = {
  onComplete: PropTypes.func,
  counter: PropTypes.number
};
MyLinearProgress.defaultProps = {
  counter: 5
};
export default MyLinearProgress;
