import {
  VERIFY_USER,
  SIGN_OUT,
  VERIFY_EMAIL,
  SUBMIT_PASSWORD,
  CREATE_USER,
  HIDE_SIGNUP_ERROR,
  REMOVE_VERIFY_EMAIL_MESSAGE,
  REMOVE_SUBMIT_PASSWORD_MESSAGE,
  HIDE_SIGNIN_ERROR
} from "../actionTypes/user";

export const verifyUser = (user, callback, id) => ({
  type: VERIFY_USER,
  user,
  callback,
  id
});
export const hideSignUpError = () => ({
  type: HIDE_SIGNUP_ERROR
});
export const hideSignInError = () => ({
  type: HIDE_SIGNIN_ERROR
});
export const createUser = (user, callback, id) => ({
  type: CREATE_USER,
  user,
  callback,
  id
});
export const signOut = () => ({
  type: SIGN_OUT
});
export const verifyEmail = (email, id) => ({
  type: VERIFY_EMAIL,
  email,
  id
});
export const hideVerifyEmailError = () => ({
  type: REMOVE_VERIFY_EMAIL_MESSAGE
});
export const hideRestorePasswordError = () => ({
  type: REMOVE_SUBMIT_PASSWORD_MESSAGE
});
export const submitPassword = (password, userCode, callback, id) => ({
  type: SUBMIT_PASSWORD,
  password,
  userCode,
  callback,
  id
});
