import React from "react";
import { useSelector } from "react-redux";

const UseThreeArrowKeys = () => {
  const id = useSelector(store => store.language.id);
  const arrowsBank = [
    "You can use arrows: LEFT for (>), DOWN for (=) and RIGHT for (<) to answer faster",
    "Вы можете использовать стрелки: ВЛЕВО для (>), ВНИЗ для (=) и ВПРАВО для (<) что бы отвечать быстрее",
    "Jūs galite naudoti rodykles: KAIRĖ kaip (>), ŽEMYN kaip (=) ir DEŠINĖ kaip (<), kad atsakyti greičiau",
    "Sie können Pfeile benutzen: LINKS für (>), UNTEN für (=) und RECHTS für (<), um schneller zu antworten"
  ];
  return (
    <h2
      style={{
        width: "80%",
        padding: "5px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "5px",
        borderColor: "#808080",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        fontStyle: "italic"
      }}
    >
      {arrowsBank[id]}
    </h2>
  );
};
export default UseThreeArrowKeys;
