import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Formik } from "formik";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  verifyUser,
  verifyEmail,
  hideVerifyEmailError,
  hideSignInError
} from "../../state/actions/user";
import {
  passwordValidation,
  emailValidation
} from "../../common/TextFieldValidation";
import FormError from "../common/FormError";

const title = ["Sign in", "Вход", "Prisijungimas", "Einloggen"];
const signinBank = ["Sign in", "Войти", "Prisijungti", "Einloggen"];
const forgotBank = [
  "Forgot your password?",
  "Забыли пароль?",
  "Pamiršot slaptažodį?",
  "Kennwort vergessen?"
];
const emailBank = [
  "Email has been sent",
  "Письмо отправлено",
  "El. Laiškas išsiųstas",
  "Email wurde verschickt"
];
const emailErrorBank = [
  "Email must be valid",
  "Email должен быть действительным",
  "El. Paštas turi būti galiojantis",
  "Email muss gültig sein"
];
const signinErrorBank = [
  "Sign in failed",
  "Ошибка входа",
  "Nepavyko prisijungti",
  "Anmeldung fehlgeschlagen"
];
const passwordBank = ["Password", "Пароль", "Slaptažodis", "Passwort"];

const SignInForm = () => {
  const [localSignInError, setLocalSignInError] = React.useState(false);
  const [localEmailError, setLocalEmailError] = React.useState(false);
  const verifyEmailSuccess = useSelector(
    store => store.user.verifyEmailSuccess
  );
  const isSubmiting = useSelector(store => store.user.isSubmiting);
  const emailError = useSelector(store => store.user.verifyEmailError);
  const signInError = useSelector(store => store.user.signInError);
  const user = useSelector(store => store.user.user);
  const id = useSelector(store => store.language.id);
  const dispatch = useDispatch();
  const history = useHistory();
  const matches = useMediaQuery("(max-width:500px)");

  useEffect(() => {
    if (user) {
      history.push("/games/all");
    }
  }, [history, user]);

  const verifyForm = (email, password) => {
    if (emailValidation(email) && passwordValidation(password)) {
      setLocalSignInError(false);
      setLocalEmailError(false);
      submit(email, password);
    } else {
      setLocalSignInError(true);
      setLocalEmailError(false);
    }
  };

  const verifyEmailForm = email => {
    if (emailValidation(email)) {
      setLocalSignInError(false);
      setLocalEmailError(false);
      submitEmail(email);
    } else {
      setLocalSignInError(false);
      setLocalEmailError(true);
    }
  };

  const submitEmail = email => {
    dispatch(verifyEmail(email, id));
    setTimeout(() => {
      dispatch(hideVerifyEmailError());
    }, 10000);
  };

  const redirect = () => {
    history.push("/games/all");
  };

  const submit = (email, password) => {
    const formData = {
      email: email,
      password: password
    };
    dispatch(verifyUser(formData, redirect, id));
    setTimeout(() => {
      dispatch(hideSignInError());
    }, 10000);
  };

  return (
    <Container maxWidth="sm">
      <Card
        style={{
          borderRadius: "20px",
          padding: "20px",
          marginBottom: "20px",
          marginTop: "20px",
          background: "rgba(255, 255, 255, 0.9)"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: matches ? "1.6em" : "2em",
            marginTop: matches ? "5px" : "10px",
            marginBottom: matches ? "5px" : "20px"
          }}
        >
          {title[id]}
        </h1>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={values => {
            verifyForm(values.email, values.password);
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                required
                fullWidth
                name="email"
                variant="outlined"
                placeholder="Email"
                value={values.email}
                style={{ marginBottom: "20px", marginTop: "20px" }}
                onChange={handleChange}
              />
              <TextField
                required
                fullWidth
                name="password"
                variant="outlined"
                placeholder={passwordBank[id]}
                type="password"
                value={values.password}
                style={{ marginBottom: "20px" }}
                onChange={handleChange}
              />
              <Button
                type="submit"
                size={matches ? "medium" : "large"}
                variant="contained"
                color="primary"
                disabled={isSubmiting}
                style={{
                  background: "rgba(29,115,173, 0.8)",
                  width: matches ? "80%" : "60%",
                  marginLeft: matches ? "10%" : "20%",
                  marginRight: matches ? "10%" : "20%",
                  borderRadius: "20px",
                  textTransform: "none",
                  fontSize: matches ? "1.2em" : "1.4em"
                }}
              >
                {signinBank[id]}
              </Button>
              {(signInError || localSignInError) && (
                <FormError Tag={"h2"} message={signinErrorBank[id]} />
              )}
              <Button
                style={{
                  marginTop: "20px",
                  background: "rgba(29,115,173, 0.8)",
                  width: matches ? "80%" : "60%",
                  marginLeft: matches ? "10%" : "20%",
                  marginRight: matches ? "10%" : "20%",
                  borderRadius: "20px",
                  textTransform: "none",
                  fontSize: matches ? "1.2em" : "1.4em"
                }}
                disabled={isSubmiting}
                size={matches ? "medium" : "large"}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (values.email !== "") {
                    verifyEmailForm(values.email);
                  } else {
                    setLocalEmailError(true);
                  }
                }}
              >
                {forgotBank[id]}
              </Button>
            </form>
          )}
        </Formik>
        {(emailError || localEmailError) && (
          <FormError Tag={"h2"} message={emailErrorBank[id]} />
        )}
        {verifyEmailSuccess && (
          <h2
            style={{ color: "green", textAlign: "center", marginBottom: "0px" }}
          >
            {emailBank[id]}
          </h2>
        )}
      </Card>
    </Container>
  );
};

export default SignInForm;
