import {
  GET_RECORD,
  POST_RECORD,
  SET_ZERO_RECORD
} from "../actionTypes/record";

export const getRecord = id => ({
  type: GET_RECORD,
  id
});
export const setZeroRecord = () => ({
  type: SET_ZERO_RECORD
});
export const postRecord = (id, score) => ({
  type: POST_RECORD,
  id,
  score
});
